import {Component, ElementRef, ViewChild} from '@angular/core';
import {DrupalService} from '../../service/drupal/drupal.service';
import {FavoritesService} from '../../service/favorites/favorites.service';
import {Recommended} from '../../service/favorites/recommended';
import {GoogleAnalyticsService} from "../../service/google-analytics/google-analytics.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-recommend-modal',
  templateUrl: './recommend-modal.component.html'
})
export class RecommendModalComponent {
  @ViewChild('recommendModal') recommendModal: ElementRef;

  public reports;
  public loading = false;
  public recommendModalRef: NgbModalRef;
  private currentNID;

  public readonly NO_USER = 1;      // If a profile for the report could not be found
  public readonly ALREADY_HAS = 2;  // If report already has the page as recommended
  public readonly FAILED = 3;       // If the request to patch the reports recommended failed
  public readonly SUCCESS = 4;      // If the request to patch the reports recommended succeeded

  constructor(
    private drupalService: DrupalService,
    private favoriteService: FavoritesService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private modalService: NgbModal
  ) {
  }

  open(reports, nid) {
    this.reports = reports;
    this.currentNID = nid;
    this.clearStatus();
    this.deselectAll();
    this.recommendModalRef = this.modalService.open(this.recommendModal);
  }

  /**
   * Retrieves all selected reports current recommendations and appends the current page if it is not included
   */
  send() {
    this.loading = true;
    // Get selected reports
    let selected = [];
    for (let report of this.reports) {
      if (report['checked']) {
        selected.push(report);
      }
    }
    // Add page to report's recommended feed
    let addQueue = [];
    for (let report of selected) {
      addQueue.push(this.addPageToReportsRecommended(report));
    }

    this.googleAnalyticsService.sendAction('RecommendationSent', {
      nid: this.currentNID,
      recipient_count: selected.length
    });

    Promise.all(addQueue).then(() => {
      this.loading = false
    })
  }

  /**
   * Gets reports current recommendations and removes any garbage entries that are empty strings (just in case)
   * @param report
   */
  async addPageToReportsRecommended(report) {
    const user = await this.drupalService.getUserInfo(report.loginUsername);
    if (!user[0]) {
      report['status'] = this.NO_USER;
      return;
    }

    const currentRecommended: Recommended = this.favoriteService.parseRecommendations(user[0].field_wws_recommended);
    const leader = await this.drupalService.getUserInfo();
    currentRecommended.leaderId = (leader[0]['uid']) ? leader[0]['uid'] : "";

    if (currentRecommended.recommendations.includes(this.currentNID.toString())) {
      report['status'] = this.ALREADY_HAS;
      return;
    }

    currentRecommended.recommendations.push(this.currentNID.toString());
    return this.drupalService.patchRecommended(currentRecommended, user[0].uid)
      .then(() => report['status'] = this.SUCCESS).catch(() => report['status'] = this.FAILED)
  }

  /**
   * Clear all statuses for reports list
   */
  clearStatus() {
    for (let report of this.reports) {
      report['status'] = null;
    }
  }

  deselectAll() {
    for (let report of this.reports) {
      report['checked'] = false;
    }
  }

  selectAll() {
    for (let report of this.reports) {
      report['checked'] = true;
    }
  }

}
