<div class="card resource-links big">
  <h3>{{title}} <span class="loader-lite" *ngIf="loading"></span></h3>
  <!--<div class="no-results" *ngIf="feed.length === 0 && !loading">Nothing to show.</div>-->

  <!-- Resources -->
  <div class="big">
    <div class="exposure-filter"
         [ngClass]="{'first': filter === '', 'second': filter === 'customer', 'third': filter === 'internal'}">
      <div (click)="selectFilter('')">All
      </div>
      <div (click)="selectFilter('customer')">Customer-facing
      </div>
      <div (click)="selectFilter('internal')">Internal
      </div>
    </div>
    <div class="no-results" *ngIf="feedItems.length === 0 && !loading">Nothing to show.</div>
    <div *ngFor="let group of feedItems; let i = index">
      <h5 *ngIf="group.title !== ''" (click)="group.open = !group.open; group.open ? sendGaAccordionExpansionEvent(group): '';"><span
        [innerHTML]="group.title"></span><i class="fas fa-caret-up" *ngIf="group.open"></i><i class="fas fa-caret-down"
                                                                                              *ngIf="!group.open"></i>
      </h5>
      <ul [ngClass]="{'list-closed' : !group.open}">
        <li *ngFor="let item of group.pages"
            [ngClass]="{'hidden': item.field_confidentiality.toLowerCase().indexOf(filter) === -1}"><a
          routerLink="/{{getURLString(item.field_category)}}/{{item.nid}}" [innerHTML]="item.title"
          [gaEvent]="gaContentEvent(item)"></a></li>
      </ul>
      <hr *ngIf="i < feedItems.length-1">
    </div>
  </div>
</div>

<!-- Resources Small Size -->
<div class="small" style="margin-top:40px;">
  <hr>
  <h1>{{title}} <span class="loader-lite" *ngIf="loading"></span></h1>
  <div class="exposure-filter small-filter"
       [ngClass]="{'first': filter === '', 'second': filter === 'customer', 'third': filter === 'internal'}">
    <div (click)="selectFilter('')">All
    </div>
    <div (click)="selectFilter('customer')">Customer-facing
    </div>
    <div (click)="selectFilter('internal')">Internal
    </div>
  </div>
  <div class="no-results" *ngIf="feedItems.length === 0 && !loading">Nothing to show.</div>
  <div *ngFor="let group of feedItems">
    <h2 *ngIf="group.title !== ''" [innerHTML]="group.title"></h2>
    <ul>
      <li *ngFor="let item of group.pages"
          [ngClass]="{'hidden': item.field_confidentiality.toLowerCase().indexOf(filter) === -1}"><a
        routerLink="/{{getURLString(item.field_category)}}/{{item.nid}}" [innerHTML]="item.title"
        [gaEvent]="gaContentEvent(item)"></a></li>
    </ul>
  </div>
</div>
