import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StringService} from '../../service/string/string.service';
import {DrupalService} from '../../service/drupal/drupal.service';
import {FILTERS} from '../../service/filter/filter-presets';
import {FilterService} from '../../service/filter/filter.service';
import {FeedComponent} from '../feed.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
})
export class AudioComponent extends FeedComponent implements OnInit {

    constructor(
        private filterService: FilterService,
        private router: Router
    ) {
        super();
        this.init(window['NUM_AUDIO_STANDINS'], window['NUM_AUDIO_DISPLAY']);
        this.title = "Podcasts";
    }

    /**
     * Get feed pages and information from passed meta info
     */
    ngOnInit() {
        this.loading = true;
        this.getFeedContent(FILTERS.AUDIO_FILTER, this.filterService).then(() => this.loading = false)
    }

    getURLString(item){
        return StringService.getURLString(item);
    }

    stripContainingHTML(string){
        return StringService.stripContainingHTML(string);
    }

    gaContentEvent(item) {
        return {
            event_name: 'Content',
            nid: item.nid,
            source: this.router.url,
            root_screen: item.field_category,
            title: item.title,
            media_title: item.field_media?.split('^')[0],
            content_type: item.field_type,
            advanced_content_type: item.field_advanced_search,
            organizations: item.field_organization_tids,
            locations: item.field_locales,
            searching: false
        }
    }

}
