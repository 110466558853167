<div>
  <h6>
    <span [innerHTML]="page.field_summary"></span>
  </h6>
</div>
<!--Contact Information-->
<div class="resource-links">
  <div class="custom-dropdown-header" (click)="contactOpen = !contactOpen"
       [gaEvent]="gaAccordionEvent('Contact Information')">
    <h5>Contact Information</h5>
    <div class="arrow-icons">
      <i class="fas fa-caret-up" *ngIf="contactOpen"></i>
      <i class="fas fa-caret-down" *ngIf="!contactOpen"></i>
    </div>
  </div>
  <ul [ngClass]="{'list-closed' : !contactOpen}">
    <li>
      <div class="list-wrapper">
        <div class="collapsible">
          <span [innerHTML]="page.body"></span>
        </div>
      </div>
    </li>
  </ul>
</div>

<!--Filters-->
<div *ngIf="!loading && programs.length > 0" class="filter-wrapper">
  <app-filter (update)="update()" [(filtersList)]="filters" [selectedCount]="numSelected" [filterCount]="numFilters"></app-filter>
</div>
<div class="oem-dropdown" *ngIf="!loading">
  <div class="group-item" *ngFor="let program of filtered">
    <!--          Program Dropdowns           -->
    <div class="custom-dropdown-header" (click)="toggleDropdown(program, 'program', program, program.title)">
      <h5>
        <span>{{program.title}}</span>
      </h5>
      <div class="icon-wrapper">
        <i *ngIf="program.status === 'In Program'" class="fas fa-circle" title="In Program"></i>
        <i *ngIf="program.status === 'Coming Soon'" class="far fa-dot-circle" title="Coming Soon"></i>
        <i *ngIf="program.status === 'Not In Program'" class="far fa-circle" title="Not In Program"></i>
        <i *ngIf="program.updated_at > recent" class="fas fa-history" title="Recently Updated"></i>
        <i *ngIf="program.competitive_info.info.length > 0" class="fas fa-user-secret" title="Competitive Information"></i>
        <i *ngIf="program.faq.info.length > 0" class="fas fa-question-circle" title="FAQ"></i>
        <div class="arrow-icons">
          <i class="fas fa-caret-up" *ngIf="program.open"></i>
          <i class="fas fa-caret-down" *ngIf="!program.open"></i>
        </div>
      </div>
    </div>

    <ul [ngClass]="{'list-closed' : !program.open}">
      <div class="list-wrapper">
        <div class="collapsible">
          <!--            Program Information             -->
          <li *ngIf="program.program_info.info.length > 0">
            <div class="custom-dropdown-header" (click)="toggleDropdown(program.program_info, 'no-child', program, 'Program Information')">
              <h5>
                <span>Program Information</span>
              </h5>
              <div class="arrow-icons">
                <i class="fas fa-caret-up" *ngIf="program.program_info.open"></i>
                <i class="fas fa-caret-down" *ngIf="!program.program_info.open"></i>
              </div>
            </div>
            <ul [ngClass]="{'list-closed' : !program.program_info.open}">
              <div class="list-wrapper">
                <div class="collapsible">
                  <li>
                    <div class="custom-dropdown-header final">
                      <span [innerHTML]="program.program_info.info"></span>
                    </div>
                  </li>
                </div>
              </div>
            </ul>
          </li>
          <!--          ASB Info Dropdown            -->
          <li *ngIf="program.asblinks.info.length > 0">
            <div class="custom-dropdown-header" (click)="toggleDropdown(program.asblinks, 'no-child', program, 'ASB Information')">
              <h5>
                <span>ASB Information</span>
              </h5>
              <div class="arrow-icons">
                <i class="fas fa-caret-up" *ngIf="program.asblinks.open"></i>
                <i class="fas fa-caret-down" *ngIf="!program.asblinks.open"></i>
              </div>
            </div>
            <ul [ngClass]="{'list-closed' : !program.asblinks.open}">
              <div class="list-wrapper">
                <div class="collapsible">
                  <li *ngFor="let item of program.asblinks.info">
                    <div class="custom-dropdown-header final">
                      <span [innerHTML]="item"></span>
                    </div>
                  </li>
                </div>
              </div>
            </ul>
          </li>
          <!--          Competitive Info Dropdown           -->
          <li *ngIf="program.competitive_info.info.length > 0">
            <div class="custom-dropdown-header" (click)="toggleDropdown(program.competitive_info, 'no-child', program, 'Competitive Information')">
              <h5>
                <span>Competitive Information</span>
              </h5>
              <div class="arrow-icons">
                <i class="fas fa-caret-up" *ngIf="program.competitive_info.open"></i>
                <i class="fas fa-caret-down" *ngIf="!program.competitive_info.open"></i>
              </div>
            </div>
            <ul [ngClass]="{'list-closed' : !program.competitive_info.open}">
              <div class="list-wrapper">
                <div class="collapsible">
                  <li>
                    <div class="custom-dropdown-header final">
                      <span [innerHTML]="program.competitive_info.info"></span>
                    </div>
                  </li>
                </div>
              </div>
            </ul>
          </li>
          <!--          FAQ Dropdown           -->
          <li *ngIf="program.faq.info.length > 0">
            <div class="custom-dropdown-header" (click)="toggleDropdown(program.faq, 'one-child', program, 'FAQ')">
              <h5>
                <span>FAQ</span>
              </h5>
              <div class="arrow-icons">
                <i class="fas fa-caret-up" *ngIf="program.faq.open"></i>
                <i class="fas fa-caret-down" *ngIf="!program.faq.open"></i>
              </div>
            </div>
            <ul [ngClass]="{'list-closed' : !program.faq.open}">
              <div class="list-wrapper">
                <div class="collapsible">
                  <ng-container *ngFor="let qa of program.faq.info">
                    <li>
                      <div class="custom-dropdown-header" (click)="toggleDropdown(qa, 'no-child', program, qa.question)">
                        <h5>
                          <span>{{qa.question}}</span>
                        </h5>
                        <div class="arrow-icons">
                          <i class="fas fa-caret-up" *ngIf="qa.open"></i>
                          <i class="fas fa-caret-down" *ngIf="!qa.open"></i>
                        </div>
                      </div>
                      <ul [ngClass]="{'list-closed' : !qa.open}">
                        <div class="list-wrapper">
                          <div class="collapsible">
                            <li>
                              <div class="custom-dropdown-header final">
                                <span [innerHTML]="qa.answer"></span>
                              </div>
                            </li>
                          </div>
                        </div>
                      </ul>
                    </li>
                  </ng-container>
                </div>
              </div>
            </ul>
          </li>
          <!--        Related Links       -->
          <li *ngIf="program.related_content.info.length > 0">
            <div class="custom-dropdown-header" (click)="toggleDropdown(program.related_content, 'one-child', program, 'Program Resources')">
              <h5>
                <span>Program Resources</span>
              </h5>
              <div class="arrow-icons">
                <i class="fas fa-caret-up" *ngIf="program.related_content.open"></i>
                <i class="fas fa-caret-down" *ngIf="!program.related_content.open"></i>
              </div>
            </div>
            <ul [ngClass]="{'list-closed' : !program.related_content.open}">
              <div class="list-wrapper">
                <div class="collapsible">
                  <ng-container
                    *ngIf="program.related_content.info.length > 1 || (program.related_content.info[0].section)">
                    <ng-container *ngFor="let relatedPage of program.related_content.info">
                      <li *ngIf="relatedPage.content.length">
                        <div class="custom-dropdown-header" (click)="toggleDropdown(relatedPage, 'no-child', program, relatedPage.section)">
                          <h5>
                            <span *ngIf="relatedPage.section">{{relatedPage.section}}</span>
                            <span *ngIf="!relatedPage.section">Other</span>
                          </h5>
                          <div class="arrow-icons">
                            <i class="fas fa-caret-up" *ngIf="relatedPage.open"></i>
                            <i class="fas fa-caret-down" *ngIf="!relatedPage.open"></i>
                          </div>
                        </div>
                        <ul [ngClass]="{'list-closed' : !relatedPage.open}">
                          <div class="list-wrapper">
                            <div class="collapsible">
                              <ng-container *ngFor="let elem of relatedPage.content; let i = index;">
                                <li *ngIf="relatedPage.content[i]">
                                  <div class="custom-dropdown-header final">
                                <span><a *ngIf="!loading && relatedPage.content[i]"
                                   routerLink="/{{getURLString(relatedPage.content[i].field_category)}}/{{relatedPage.content[i].nid}}"
                                   [gaEvent]="gaContentEvent(relatedPage.content[i])"
                                >{{relatedPage.content[i].title}}</a></span>
                                  </div>
                                </li>
                              </ng-container>
                            </div>
                          </div>
                        </ul>
                      </li>
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="program.related_content.info.length === 1 && (!program.related_content.info[0].section)">
                    <li *ngFor="let item of program.related_content.info[0].content; let i = index;">
                      <div class="custom-dropdown-header final">
                        <span><a *ngIf="!loading"
                           routerLink="/{{getURLString(program.related_content.info[0].content[i].field_category)}}/{{program.related_content.info[0].content[i].nid}}"
                           [gaEvent]="gaContentEvent(program.related_content.info[0].content[i])">{{program.related_content.info[0].content[i].title}}</a></span>
                      </div>
                    </li>
                  </ng-container>
                </div>
              </div>
            </ul>
          </li>
        </div>
      </div>
    </ul>
  </div>
</div>

<h6 *ngIf="loading" style="padding-left: 10px; padding-top: 10px;">Loading...</h6>
<h6 *ngIf="!loading && this.filtered.length === 0" style="padding-top: 10px; padding-left: 10px;">{{errorText}}</h6>
