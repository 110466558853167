<div class="expand-toggle-container">
    <input class="hidden" type="checkbox" id="{{id}}">
    <label class="expand-toggle multiselect-toggle" for="{{id}}">
        <span class="dropdown_arrow"></span>
        <span class="expand-toggle-text" [innerHTML]="title"></span>
        <span class="expand-toggle-selected" *ngIf="selected.length < options.length">{{selected.length}} selected</span>
        <span class="expand-toggle-selected" *ngIf="selected.length === options.length">All selected</span>
        <div class="expand-toggle-choices">
            <label *ngFor="let option of options" [ngClass]="{'checked active' : option.checked}"
                   (click)="select(option)">
                <div class="reysat-checkmark"><span class="check"></span></div>
                <span [innerHTML]="option.name"></span>
            </label>
        </div>
        <div class="expand-toggle-buttons">
            <button (click)="deselectAll()"><i class="fas fa-times"></i> Deselect all</button>
            <button (click)="selectAll()"><i class="fas fa-check"></i> Select all</button>
        </div>
    </label>
</div>
