<div class="card resource-links">
  <h3><i class="fas fa-microphone" style="margin-right:12px;"></i>{{title}}</h3>
  <div class="no-results" *ngIf="!loading && feedItems.length === 0">Nothing to show.</div>

  <!-- Stand-ins -->
  <div *ngIf="loading">
    <div *ngFor="let page of standinObjects; let i = index">
      <div class="spotlight">
        <div class="standin-link"></div>
        <div class="standin-body-copy"></div>
      </div>
      <!--<hr *ngIf="i < standinObjects.length-1">-->
    </div>
  </div>

  <!-- Audio -->
  <div *ngIf="!loading">
      <div class="spotlight" *ngFor="let item of feedItems; let i = index">
        <span class="date" [innerHTML]="item.changed"></span><br>
        <a class="header-5 inline-link" [innerHTML]="stripContainingHTML(item.title)" routerLink="/{{getURLString(item.field_category)}}/{{item.nid}}"
           [gaEvent]="gaContentEvent(item)"></a>
        <p class="body-copy" [innerHTML]="stripContainingHTML(item.field_summary)"></p>
      </div>
      <!--<hr *ngIf="i < feedItems.length-1">-->
    </div>
    <div style="text-align: right" *ngIf="feedItems.length > 0">
      <button class="btn btn-grey" routerLink="/podcast" [gaEvent]="{category: 'Audio Feed', action: 'click', label: 'See All'}">See All</button>
    </div>
</div>
