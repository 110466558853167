import {Injectable} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class OemGridGuardService {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    /**
     * Called for every view to determine if user is authenticated before accessing
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {boolean}
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkAuth(state.url) && window['OEM_GRID_ENABLED'];
    }

    canDeactivate(component: any): boolean {
        window.dispatchEvent(new Event('beforeunload'));
        return true;
    }

    /**
     * If authenticated, proceed, otherwise intercept URL and navigate to login page
     * @param url
     * @returns {boolean}
     */
    async checkAuth(url) {
        return this.authService.isAuth().then(response => {
            if (!response || (!this.authService.profileSet && url != '/setProfile')) {
                this.authService.redirectUrl = url;
                this.router.navigate([!response ? 'login' : '/setProfile']);
                return false;
            }
            return response;
        })

    }
}
