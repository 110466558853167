import {Component, ElementRef, ViewChild} from '@angular/core';
import { FilterService } from '../../service/filter/filter.service';
import { StringService } from '../../service/string/string.service';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-customize-modal',
  templateUrl: './customize-modal.component.html'
})

export class CustomizeModalComponent {
  @ViewChild('customizeModal') customizeModal: ElementRef;

  public filter: any;
  public customizeModalRef: NgbModalRef;

  constructor(private filterService: FilterService, private modalService: NgbModal) { }

  async open() {
    this.filter = await this.filterService.getRecentUpdatesFilter();
    this.customizeModalRef = this.modalService.open(this.customizeModal);
  }

  getTitleFromField(string: string) {
    return StringService.makeTitleFromFieldName(string);
  }

  apply() {
    this.filterService.setRecentUpdatesFilter(this.filter).then(() => this.customizeModalRef.dismiss());
  }
}
