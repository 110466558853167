import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Filter} from '../../service/filter/filter.interface';
import {StringService} from '../../service/string/string.service';
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../../service/google-analytics/google-analytics.service";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html'
})
export class FilterComponent implements OnChanges {

  @Input() filtersList: Filter = {filters: []};
  @Input() filterOpen: boolean = false;
  @Input() selectedCount: number|null = null;
  @Input() filterCount: number|null = null;
  @Output() filtersListChange: EventEmitter<Filter> = new EventEmitter<Filter>();
  @Output() update: EventEmitter<any> = new EventEmitter();

  numSelected = 0;
  constructor(
      private router: Router,
      private googleAnalyticService: GoogleAnalyticsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.updateArea();
  }

  getTitleFromField(string) {
    return StringService.makeTitleFromFieldName(string);
  }

  // Toggle and update
  toggleFilter(obj, name, index) {
    obj.visibleList[name] = !obj.visibleList[name];
    obj.items[index].checked = !obj.items[index].checked;
    if (obj.title.toLowerCase() === 'organization') {
      this.updateArea();
    }
    if (obj.title.toLowerCase() === 'business area') {
      this.updateBlueDotDept(name);
    }
    this.filtersListChange.emit(this.filtersList);
    this.update.emit();
  }

  // Deselects every item in the given filter category
  deselectCategory(cat) {
    let i = 0;
    for (let item of cat.items) {
      cat.items[i].checked = false;
      cat.visibleList[item.name] = false;
      i++;
    }
    if (cat.title === 'organization') {
      this.updateArea();
    }
    if (cat.title.toLowerCase() === 'business area') {
      this.hideBlueDotDepts();
    }
    this.filtersListChange.emit(this.filtersList);
    this.update.emit();
  }

  selectCategory(cat) {
    let i = 0;
    for (let item of cat.items) {
      cat.items[i].checked = true;
      cat.visibleList[item.name] = true;
      i++;
    }
    if (cat.title === 'organization') {
      this.updateArea();
    }
    if (cat.title.toLowerCase() === 'business area') {
      this.hideBlueDotDepts();
    }
    this.filtersListChange.emit(this.filtersList);
    this.update.emit();
  }

  updateArea() {
    this.numSelected = 0;
    let area = this.filtersList.filters.find((filter => filter.title === 'business Area'));
    let orgs = this.filtersList.filters.find((filter => filter.title === 'organization'));
    if (!orgs || !area) {
      return;
    }
    for (let item of area.items) {
      if (item['field_organizations']) {
        item['organizations'] = item['field_organizations'].split(', ');
      }
      let checked = false;
      for (let org of item.organizations) {
        if (orgs.items.findIndex((org2 => org2.tid === org && org2.checked === true)) !== -1) {
          checked = true;
          break;
        }
      }
      if (checked) {
        if (item.hidden) {
          this.numSelected++;
          area.visibleList[item.name] = true;
          item.checked = true;
        }
        item.hidden = false;
      } else {
        area.visibleList[item.name] = false;
        item.hidden = true;
        item.checked = false;
      }
    }
  }

  updateBlueDotDept(name: string) {
    const deptIdx = this.filtersList.filters.findIndex(el => el.title === 'Department');
    const baIdx = this.filtersList.filters.findIndex(el => el.title === 'Business Area');

    if (deptIdx < 0 || baIdx < 0) {
      return;
    }

    const isVisible: boolean = this.filtersList.filters[baIdx].visibleList[name];
    this.filtersList.filters[deptIdx].items.forEach(item => {
      if (item.field_business_area === name) {
        item.hidden = !isVisible;
        item.checked = isVisible;
        this.filtersList.filters[deptIdx].visibleList[item.name] = isVisible;
      }
    });
  }

  hideBlueDotDepts() {
    let deptIdx = this.filtersList.filters.findIndex(el => el.title === 'Department');
    let baIdx = this.filtersList.filters.findIndex(el => el.title === 'Business Area');

    if (deptIdx < 0 || baIdx < 0) {
      return;
    }

    this.filtersList.filters[deptIdx].items.forEach(dept => {
      const isVisible: boolean = this.filtersList.filters[baIdx].visibleList[dept.field_business_area];
      dept.hidden = !isVisible;
      dept.checked = isVisible;
      this.filtersList.filters[deptIdx].visibleList[dept.name] = isVisible;
    })
  }

  sendGaFilterModalEvent(event) {
    if(event.target.checked) {
      this.googleAnalyticService.sendAction('FilterModal', {
        event_name: 'FilterModal',
        source: this.router.url
      });

      if(this.router.url.indexOf('oem/') >= 0){
        this.googleAnalyticService.sendAction('OEMFilters', {
          event_name: 'OEMFilters',
          source: this.router.url
        });
      }
    }
  }
}
