import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dropdown-multiselect',
  templateUrl: './dropdown-multiselect.component.html'
})
export class DropdownMultiselectComponent implements OnInit {

  @Input() id: string;
  @Input() title: string;
  @Input() options;

  @Output() emitInputChange: EventEmitter<any> = new EventEmitter<any>();

  public selected = [];

  constructor() { }

  ngOnInit() {
    this.checkSelected();
  }

  checkSelected() {
    this.selected = [];
    for (let o of this.options) {
      if (o['checked']) { this.selected.push(o['name']) }
    }
  }

  /**
   * Construct array of selected options and emit them to parent
   */
  emitSelected() {
    this.checkSelected();
    this.emitInputChange.emit(this.options);
  }

  /**
   * Selects option and emits new array of selected options
   * @param option
   */
  select(option) {
    option['checked'] = !option['checked'];
    this.emitSelected();
  }

  /**
   * Deselects all and emits array of selected options
   */
  deselectAll() {
    for (let option of this.options) {
      option['checked'] = false;
    }
    this.emitSelected();
  }

  /**
   * Selects all and emits array of selected options
   */
  selectAll() {
    for (let option of this.options) {
      option['checked'] = true;
    }
    this.emitSelected();
  }

}
