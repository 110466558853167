<ng-template #customizeModal id="customize-modal" tabindex="-1" role="dialog" aria-labelledby="customize-modal"
     aria-hidden="true">
  <div class="fa-times-thin modal-close" (click)="customizeModalRef.dismiss()"
       [gaEvent]="{category: 'Recent Updates Modal', action: 'click', label: 'Close'}"></div>
  <div class="modal-header source-sans">
    <h3>Recent Updates</h3>
  </div>
  <div class="modal-body source-sans">
    <div class="customize-grid" *ngIf="filter">
      <div class="customize-column" *ngFor="let filterItem of filter.filters">
        <h4 [innerHTML]="getTitleFromField(filterItem.title)"></h4>
        <ul>
          <li *ngFor="let item of filterItem.items" (click)="item['checked'] = !item['checked']">
            {{item.name}}
            <div class="star" [ngClass]="{'checked': item.checked}">
              <span class="far fa-square" *ngIf="!item.checked"></span>
              <span class="fas fa-check-square check" *ngIf="item.checked"></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="loader-container" *ngIf="!filter">
      <div class="loader"></div>
    </div>
    <div style="text-align: right">
      <div class="btn btn-special" (click)="customizeModalRef.dismiss()"
           [gaEvent]="{category: 'Recent Updates Modal', action: 'click', label: 'Cancel'}">Cancel
      </div>
      <div class="btn btn-grey" (click)="apply()"
           [gaEvent]="{category: 'Recent Updates Modal', action: 'click', label: 'Apply'}">Apply
      </div>
    </div>
  </div>
</ng-template>
