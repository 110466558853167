<!-- Page Header -->
<div style="overflow: auto; height:100%; width:100%">

  <app-header style="position:sticky; left:0"></app-header>

  <!-- Page content -->
  <div style="min-height:100vh">
    <router-outlet></router-outlet>
  </div>

  <!-- App Footer -->
  <app-footer style="position: sticky; left: 0;"></app-footer>

  <div #browserWarning class="browser-warning accent-font small-shadowed">
    <div style="display:inline-block">
      <div class="browser-warning-main">What We Sell is not optimized for your browser!</div>
      <div class="browser-warning-sub">You may experience unexpected behavior.</div>
    </div>
    <span class="fa fa-times-thin" (click)="hideWarning()"></span>
  </div>
</div>

