import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oem-icon-legend',
  templateUrl: './oem-icon-legend.component.html',
  styleUrls: ['./oem-icon-legend.component.scss']
})
export class OemIconLegendComponent implements OnInit {

  belowContent = false;
  readonly MAX_LARGE_HOME_SIZE = 1580;

  constructor() { }

  ngOnInit(): void {
    this.adjustScreensize(window);
  }

  // Resize listener
  onResize(event) {
    this.adjustScreensize(event.target);
  }

  // Helper to toggle boolean when screen size becomes too small
  adjustScreensize(window) {
    this.belowContent = (window.innerWidth <= this.MAX_LARGE_HOME_SIZE);
  }

}
