import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {DrupalService} from '../../service/drupal/drupal.service';
import {FavoritesService} from '../../service/favorites/favorites.service';
import {FilterService} from '../../service/filter/filter.service';
import {AuthService} from '../../service/auth/auth.service';
import {RecommendModalComponent} from '../recommend-modal/recommend-modal.component';
import {GoogleAnalyticsService} from "../../service/google-analytics/google-analytics.service";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnDestroy {

  @ViewChild(RecommendModalComponent) recommendModal: RecommendModalComponent;
  @ViewChild('favoriteStar') favoriteStar: ElementRef;

  public page;
  public resolvedPage;
  public relatedPages = [];
  public loading = false;
  public isFavorite = false;
  public hasRelated = false;
  public smallHomeSize = false;
  public resourcesLoading = true;
  public filters;
  public reports = [];

  private routeSub;
  private id;

  readonly MAX_LARGE_HOME_SIZE = 1580;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private drupalService: DrupalService,
    private favoritesService: FavoritesService,
    private filterService: FilterService,
    private authService: AuthService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
  }

  ngOnInit() {
    this.adjustScreenSize(window);
    this.routeSub = this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.getContent(event.snapshot.paramMap.get('id'));
      }
    });
    this.getContent(this.route.snapshot.paramMap.get('id'));
    this.reports = this.authService.directReports;
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.resolvedPage = undefined;
  }

  // Resize listener
  onResize(event) {
    this.adjustScreenSize(event.target);
  }

  // Helper to toggle boolean when screen size becomes too small
  adjustScreenSize(window) {
    this.smallHomeSize = (window.innerWidth <= this.MAX_LARGE_HOME_SIZE);
  }

  /**
   * Gets page and related page information
   * @param id
   */
  getContent(id) {
    this.id = id;
    this.loading = true;
    this.hasRelated = false;
    this.page = this.drupalService.getWithFilters('node', [{query: [{term: 'nid', values: [id]}]}]); // Save async page
    this.page.then(response => {
      if (response[0]) {
        this.googleAnalyticsService.setDataLayer({'nid': response[0].nid, 'title': response[0].title})
        this.resolveNodePage(response[0], id);
      } else {
        this.router.navigateByUrl('not_found');
      }
    });
  }

  /**
   * Returns whether the user has direct reports
   */
  hasReports() {
    return (this.authService.directReports && this.authService.directReports.length > 0);
  }

  /**
   * Opens a modal to select which direct reports will receive this page as a recommendation
   */
  recommendPage() {
    this.recommendModal.open(this.authService.directReports, this.id);
  }

  /**
   * Format page and get filters
   * @param page
   * @param nid
   */
  resolveNodePage(page, nid) {
    this.resolvedPage = page;
    this.resolvedPage.body = this.fixImages(this.resolvedPage.body);
    this.favoritesService.isFavorite(nid).then(isFave => this.isFavorite = isFave);
    this.filterService.getFilters(['organization', 'business Area', 'locale', 'language']).then(filters => {
      this.filters = this.filterService.getQuery(filters);
      this.getRelatedPages().then(() => this.loading = false);
    });
  }

  // Replace image sources with appropriate drupal location
  fixImages(body: string) {
    const parsedHtml = new DOMParser().parseFromString(body, 'text/html').querySelector('body');
    parsedHtml.querySelectorAll('img').forEach(img => {
      const imgSrc = window['DRUPAL_IMAGE_URL'] + img.getAttribute('src');
      img.setAttribute('src', imgSrc);
    });
    return parsedHtml.innerHTML;
  }

  /**
   * Gets all related groups of pages and reorders them to ensure fast load time yet still match the backend.
   */
  async getRelatedPages() {
    let groups = [];
    this.relatedPages = [];
    this.resourcesLoading = true;
    let related = await this.drupalService.getRelatedContent(this.resolvedPage.nid);
    this.hasRelated = (related.length > 0 && related[0].title !== '');
    for (let group of related) {
      groups.push(this.buildRelatedGroup(group));
    }
    // Reorder resources when it's finished
    Promise.all(groups).then(loadedGroups => {
      let newGroups = [];
      for (let group of loadedGroups) {
        if (group.pages.length > 0) {
          let newPages = new Array(group.pages.length);
          for (let rp of group.pages) {
            newPages[rp.index] = rp.page;
          }
          group.pages = newPages.filter(n => n); // Used to perform deep copy
          newGroups.push(group);
        } else {
          group.title = '';
        }
      }
      this.hasRelated = (newGroups.length > 0);
      this.relatedPages = (newGroups.length > 0) ? newGroups : this.relatedPages;
      this.resourcesLoading = false;
    });
  }

  /**
   * Takes a group of ordered indices and fetches their pages.
   * @param group
   * @returns {Promise<any>}
   */
  private async buildRelatedGroup(group) {
    const relatedGroup = {title: group.title, pages: [], open: false};
    const relatedGroupPromises = [];
    for (let i = 0; i < group.pages.length; i++) {
      if (!group.pages[i]) {
        continue;
      }
      const query = this.filters.concat({term: 'nid', values: [group.pages[i]]});
      relatedGroupPromises.push(
        this.drupalService.getWithFilters('node', [{'query': query}]).then(content => {
          if (content && content.length > 0) {
            relatedGroup.pages.push({page: content[0], index: i});
          }
        })
      );
    }
    await Promise.all(relatedGroupPromises);

    return relatedGroup;
  }

  /**
   * Toggles the favorite status of the page
   */
  toggleFavorite() {
    const icon = this.favoriteStar.nativeElement;
    if (this.isFavorite) {
      this.favoritesService.removeFavorite(this.id);
      this.renderer.removeClass(icon, 'pulse');
      this.renderer.addClass(icon, 'throb');
    } else {
      this.favoritesService.addFavorite(this.id);
      this.renderer.addClass(icon, 'pulse');
      this.renderer.removeClass(icon, 'throb');
    }
    this.isFavorite = !this.isFavorite;
  }

}
