import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {StringService} from '../../service/string/string.service';
import {AuthService} from '../../service/auth/auth.service';
import {PersonalizationService} from '../../service/personalization/personalization.service';
import {ToastrService} from "ngx-toastr";
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html'
})
export class ProfileModalComponent implements AfterViewInit {
  @ViewChild('profileModal') profileModalRef: ElementRef;

  public rdsid;
  public filter;
  public done = true;
  modal: NgbModalRef;
  baCount = 0;
  orgCount = 0;
  errorMessage: string;

  constructor(
    private personalizationService: PersonalizationService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
  }

  ngAfterViewInit() {
    this.rdsid = window['RDSID'];
    this.open();
  }

  /**
   * Gets profile filters, reorders to accommodate RDS, and updates languages
   */
  open() {
    // This grabs the personal profile filters, or default if there are none saved
    this.personalizationService.getProfileFilters().then(response => {
      this.filter = JSON.parse(JSON.stringify(response));
      // If there is no profile set yet, then set that it is not done
      if (!this.personalizationService.profile) {
        this.done = false;
      }
      // Put RDS last
      this.reorderOrganization(this.filter);
      // This will hide language based on the locale selected
      this.updateLanguage(true);
      // This will hide business areas based on the organizations selected
      this.updateBusinessArea(true);
    });
    this.modal = this.modalService.open(this.profileModalRef, {backdrop: 'static'});
    this.modal.result.then(() => {}, reason => {
      const url = this.authService.redirectUrl.length ? this.authService.redirectUrl : '/home';
      this.router.navigate([url]);
    });
  }

  /**
   * Reorders the organization filter so that RDS is at the bottom for styles sake
   */
  reorderOrganization(filter) {
    for (let f of filter.filters) {
      if (f.title === 'organization') {
        let rdsIndex;
        for (let i = 0; i < f.items.length; i++) {
          if (f.items[i].tid === this.rdsid) {
            rdsIndex = i;
          }
          if (f.items[i].checked) {
            this.orgCount++;
          }
        }
        let rds = f.items[rdsIndex];
        f.items.splice(rdsIndex, 1);
        f.items.push(rds);
        break;
      }
    }
  }

  getTitleFromField(string) {
    return StringService.makeTitleFromFieldName(string);
  }

  /**
   * Update language choice
   * @param start
   */
  updateLanguage(start) {
    let lang = this.filter.filters.find(filter => filter.title === 'language');
    let locale = this.filter.filters.find(filter => filter.title === 'locale');
    if (locale.items.findIndex(filter => filter.tid === window['LOCALEID'] && filter.checked) !== -1) {
      lang.hidden = true;
      for (let item of lang.items) {
        item.checked = (item.tid === window['LANGUAGEID'][0]);
      }
    } else {
      lang.hidden = false;
      for (let item of lang.items) {
        if (item.tid === window['LANGUAGEID'][1] || item.tid === window['LANGUAGEID'][2]) {
          item.hidden = false;
          if (!start) {
            item.checked = true;
          }
        } else {
          item.hidden = true;
          item.checked = (item.tid === window['LANGUAGEID'][0]);
        }
      }
    }
  }

  /**
   * Update business area choices
   * @param start
   */
  updateBusinessArea(start) {
    this.baCount = 0;
    let area = this.filter.filters.find(filter => filter.title === 'business Area');
    let orgs = this.filter.filters.find(filter => filter.title === 'organization');
    let hideArea = true;
    if (!orgs || !area) {
      if (area) {
        area.hidden = true;
        return;
      }
    }
    for (let item of area.items) {
      if (item['field_organizations']) {
        item['organizations'] = item['field_organizations'].split(', ');
      }
      let checked = false;
      let foundOrg = null;
      for (let org of item.organizations) {
        if (orgs.items.findIndex(org2 => org2.tid === org && org2.checked === true) !== -1) {
          checked = true;
          foundOrg = orgs.items.find(org2 => org2.tid === org && org2.checked === true);
          break;
        }
      }
      if (checked) {
        hideArea = false;
        if (item.hidden) {
          area.visibleList[item.name] = true;
          if (!start) {
            item.checked = (foundOrg['field_business_area_type'] === 'On');
          }
        }
        item.hidden = false;
      } else {
        area.visibleList[item.name] = false;
        item.hidden = true;
        item.checked = false;
      }
      if (item.checked && !item.hidden) {
        this.baCount++;
      }
    }
    area.hidden = hideArea;
  }

  // Helper method to uncheck the RDS option
  private uncheckRDS(filter) {
    let ite = filter['items'].find(it => it.tid === window['RDSID']);
    if (ite) {
      ite.checked = false;
    }
  }

  /**
   * Toggle filter and update associated options
   * @param item
   * @param filter
   */
  toggleFilter(item, filter) {
    let swap = (filter.title === 'locale' || (filter.title === 'organization' && item.tid === window['RDSID']));
    // If we select option other than RDS, uncheck RDS
    if (filter.title === 'organization' && item.tid !== window['RDSID']) {
      this.uncheckRDS(filter);
    }
    // If we are swapping out of a category, deselect its stuff
    if (swap && !item['checked']) {
      for (let f of filter['items']) {
        f['checked'] = false;
      }
    }
    // Wait to update item['checked'] for locale to force one option to always be checked.
    if (filter.title !== 'locale') {
      item['checked'] = !item['checked'];
    }
    // Update language based on new locale
    if (filter.title === 'locale') {
      // Remove's ability to uncheck a locale without selecting another
      if (!item['checked']) {
        item['checked'] = !item['checked'];
        this.updateLanguage(false);
      }
    }
    // Update business areas based on new organization
    if (filter.title === 'organization') {
      if (item['checked']) {
        this.orgCount++;
      } else {
        this.orgCount--;
      }
      this.updateBusinessArea(false);
    }

    if (filter.title === 'business Area') {
      if (item['checked']) {
        this.baCount++;
      } else {
        this.baCount--;
      }
    }
  }

  /**
   * Save options and close modal
   */
  apply() {
    if (this.saveFilter()) {
      this.toastr.success('User settings applied.', 'Success', {timeOut: 1500});
    } else {
      this.toastr.error(this.errorMessage, 'There was a problem');
    }
  }

  /**
   * This cancels and redirects the user to the last page they were on, or home if redirect was not set
   */
  cancel() {
    if (this.authService.profileSet) {
      this.modal.dismiss();
    }
  }

  /**
   * Save filter and navigate to saved url
   */
  saveFilter() {
    this.authService.profileSet = true;
    for (let filter of this.filter.filters) {
      // If the filter does not have anything selected, do not save
      if (filter['items'].findIndex(it => it.checked === true) < 0) {
        this.errorMessage = 'You must select at least one ' + filter['title'];
        return false;
      }
      if (filter['title'] === 'organization') {
        // If there is no "business Area" filter found, do not save
        if (this.filter.filters.findIndex(areaFilt => areaFilt.title === 'business Area') < 0) {
          this.errorMessage = 'No business area were found for the selected organizations. Please select another organization.'
          return false;
        }
        let areas = this.filter.filters.find(areaFilt => areaFilt.title === 'business Area');
        for (let org of filter.items) {
          // Check if each selected organization has at least one of its business areas selected
          if (org.checked === true) {
            let areaSelected = false;
            for (let areaItem of areas.items) {
              if (areaItem.organizations.findIndex(org2 => org2 === org.tid) !== -1 && areaItem.checked === true) {
                areaSelected = true;
                break;
              }
            }
            // If none of the organization's business areas are selected, do not save
            if (!this.baCount) {
              this.errorMessage = 'You must select at least one business area.'
              return false;
            }
          }
        }
      }
    }
    this.personalizationService.saveProfile(this.filter).then(() => this.modal.dismiss());
    this.done = true;
    return true;
  }
}
