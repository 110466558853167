import {Component, OnInit, Inject, Input} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {FilterService} from '../../service/filter/filter.service';
import {Filter} from '../../service/filter/filter.interface';
import {DrupalService} from '../../service/drupal/drupal.service';
import {StringService} from '../../service/string/string.service';
import {GoogleAnalyticsService} from "../../service/google-analytics/google-analytics.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-oem-info',
  templateUrl: './oem-info.component.html',
  styleUrls: ['./oem-info.component.scss']
})
export class OemInfoComponent implements OnInit {

  @Input() page: any;

  filters: Filter = {filters: []};
  today = new Date();
  recent: number;
  contactOpen = false;
  loading: boolean;
  errorText: string;
  programs = [];
  filtered = []
  franchises = [];
  initialFilters = null;
  numSelected = 0;
  numFilters = 0;

  constructor(
    @Inject(DOCUMENT) document,
    private filterService: FilterService,
    private drupalService: DrupalService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  async ngOnInit() {
    this.loading = true;
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0) {
        this.initialFilters = params
      }
    });
    this.today.setDate(new Date().getDate() - 30);
    this.recent = this.today.getTime() / 1000;
    this.filters = await this.filterService.getBlueDotFilters();
    this.filters.filters.forEach(group => this.numFilters += group.items.length);
    this.franchises = this.page.field_franchises.split(', ');
    if (this.page.field_franchises) {
      this.errorText = 'No programs found associated with this OEM.';
      const locale = await this.getLocales();
      this.programs = await this.drupalService.getOemPrograms(this.franchises, locale);
      this.linkRelatedResources();
      this.filtered = [...this.programs];
    } else {
      this.errorText = 'There are currently no franchises associated with this OEM.';
    }
    this.setInitialFilters();
    this.loading = false;
  }

  setInitialFilters() {
    if (this.initialFilters) {
      this.filters.filters[0].items.forEach(item => {
        item.checked = this.initialFilters['ba'] === item['tid'];
        this.filters.filters[0].visibleList[item.name] = item.checked;
      });

      this.filters.filters[1].items.forEach(item => {
        item.checked = item['tid'] === this.initialFilters['department'];
        item.hidden = !this.filters.filters[0].visibleList[item['field_business_area']];
        this.filters.filters[1].visibleList[item.name] = item.checked;
      });

      if (this.initialFilters['type'] !== 'null') {
        this.filters.filters[2].items.forEach(item => {
          item.checked = item['tid'] === this.initialFilters['type'];
          this.filters.filters[2].visibleList[item.name] = item.checked;
        })
      }

      this.update();
    }
  }

  toggleDropdown(dropdown, type, program, title) {
    switch (type) {
      case 'program':
        if (dropdown.open) {
          for (let key in dropdown) {
            let info = dropdown[key];
            if (info.open !== undefined) {
              info.open = false;
            }
            if (key === 'related_content' || key === 'faq') {
              info.info.forEach(item => {
                item.open = false;
              });
            }
          }
        }
        dropdown.open = !dropdown.open;
        break;
      case 'one-child':
        if (dropdown.open) {
          dropdown.info.forEach(item => {
            item.open = false;
          });
        }
      case 'no-child':
        dropdown.open = !dropdown.open;
        break;
      default:
        break;
    }

    if (dropdown.open) {
      this.sendGaAccordionEvents(type, program, title);
    }
  }

  update() {
    let selectedTypes = [];
    let selectedDepts = [];
    let selectedStatuses = [];
    let selectedBusinessAreas = [];
    this.filters.filters.forEach(filter => {
      switch(filter.title.toLowerCase()) {
        case 'program type':
          selectedTypes = filter.items.filter(item => item.checked);
          break;
        case 'program status':
          selectedStatuses = filter.items.filter(item => item.checked);
          break;
        case 'department':
          selectedDepts = filter.items.filter(item => item.checked);
          break;
        case 'business area':
          selectedBusinessAreas = filter.items.filter(item => item.checked);
          break;
        default:
          break;
      }
    });

    this.numSelected = selectedTypes.length + selectedStatuses.length + selectedDepts.length + selectedBusinessAreas.length;

    this.programs.forEach(program => {
      program.hide = true;
      program.department.forEach(department => {
        if (!!selectedDepts.find(dept => dept.name === department)) {
          program.hide = false;
        }
      });
      if (!selectedTypes.find(type => type.name === program.program_type)) {
        program.hide = true;
      }
      if (!selectedStatuses.find(status => status.name === program.status)) {
        program.hide = true;
      }
    });
    this.filtered = this.programs.filter(program => !program.hide);
    if (!this.filtered.length && this.programs.length) {
      this.errorText = 'No programs found with the selected filters.';
    }
  }

  linkRelatedResources() {
    this.programs.forEach(program => {
      program.related_content.info.forEach(section => {
        if (!section.content) {
          section.content = [];
        }
        section.page_ids.forEach((id, i) => {
          this.drupalService.getWithFilters('node', [{query: [{term: 'nid', values: [id]}]}]).then(content => {
            if (content && content.length > 0) {
              section.content.push(content[0]);
            }
          });
        });
      });
    });
  }

  getURLString(item: string = '') {
    return StringService.getURLString(item);
  }

  async getLocales() {
    let result = [];
    const localeFilter = await this.filterService.getFilters(['locale']);
    localeFilter.filters[0].items.forEach(locale => {
      if (locale.checked) {
        result.push(locale.tid);
      }
    });
    return result;
  }

  gaContentEvent(item) {
    return {
      event_name: 'Content',
      nid: item.nid,
      source: this.router.url,
      root_screen: item.field_category,
      title: item.title,
      media_title: item.field_media?.split('^')[0],
      content_type: item.field_type,
      advanced_content_type: item.field_advanced_search,
      organizations: item.field_organization_tids,
      locations: item.field_locales ? item.field_locales : item.field_locale,
      searching: false
    };
  }

  sendGaAccordionEvents(type, program, title) {
    let oemEventProps: {
      event_name: string,
      nid: number,
      title: string,
      scroll_location?: string
    };

    if (type === 'program') {
      oemEventProps = {
        event_name: 'ProgramExpansion',
        nid: this.page.nid,
        title: title
      }
    } else {
      oemEventProps = {
        event_name: 'OEMProgram',
        nid: program.nid,
        title: program.title,
        scroll_location: title
      }
    }

    this.googleAnalyticsService.sendAction(oemEventProps.event_name, oemEventProps);
    this.googleAnalyticsService.sendAction('AccordionExpansion', this.gaAccordionEvent(title));
  }

  gaAccordionEvent(title) {
    return {
      event_name: 'AccordionExpansion',
      source: this.router.url,
      title: title
    }
  }
}
