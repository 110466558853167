import {Injectable} from '@angular/core';

declare const gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() {
  }

  public sendAction(eventName: string, eventProperties: any) {
    gtag('event', eventName, eventProperties);
  }

  public setDataLayer(properties: any){
    gtag('set', properties)
  }

  formatUserFilterProperties(filters) {
    const organizations = filters
      .find(filter => filter.title === 'organization')
      .items
      .filter(locale => locale.checked)
      .map(locale => locale.tid)
      .join(',');

    const languages = filters
      .find(filter => filter.title === 'language')
      .items
      .filter(locale => locale.checked)
      .map(locale => locale.tid)
      .join(',');

    const locales = filters
      .find(filter => filter.title === 'locale')
      .items
      .filter(locale => locale.checked)
      .map(locale => locale.tid)
      .join(',');

    return {
      organizations: organizations,
      languages: languages,
      locales: locales
    }
  }

  mapUserRoles(roles) {
    let rules = roles.split(', ');
    let gaRole = { role: 'sales' };

    if (rules.includes('Administrator')) {
      gaRole.role = 'development'
    } else if (rules.includes('MKT ReySat Content Creator') || rules.includes('MKT ReySat Content Approver')) {
      gaRole.role = 'marketing'
    }

    return gaRole;
  }

  setUserProperties(properties): void {
    gtag('set', 'user_properties', properties);
  }

  sendPageViewEvent(title: string, path: string, location: string) {
    gtag('event', 'page_view', {
      page_title: title,
      page_path: path,
      page_location: location
    });
  }

  extractUtmParams(initialParams) {
    const utmParamKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_id', 'utm_content'];
    return Object.keys(initialParams)
      .filter(key => utmParamKeys.includes(key))
      .reduce((utmParam, key) => {
        utmParam[key] = initialParams[key];
        return utmParam;
      }, {});
  }
}
