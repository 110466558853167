<div class="card">
    <h3 class="special"><i class="fas fa-star" style="margin-right:12px;"></i>Favorites</h3>
    <div class="favorites-filter">
        <div class="exposure-filter large-filter two-filter" [ngClass]="{'first': filter === '', 'second': filter === 'recommended'}">
            <div (click)="filter = ''">My Favorites</div>
            <div (click)="filter = 'recommended'">Recommended</div>
        </div>
    </div>
    <div class="no-results" *ngIf="(filter !== 'recommended' && favoriteItems.length === 0 && !loading) ||
    (filter === 'recommended' && recommendedItems.length === 0 && !loading)">Nothing to show.</div>
    <div class="no-results" *ngIf="loading">Loading...</div>
    <div class="spotlight-holder" *ngIf="filter !== 'recommended' && !loading">
        <div class="spotlight" *ngFor="let page of favoriteItems">
            <div class="star" *ngIf="editFavorites" (click)="page['checked'] = !page['checked']" [ngClass]="{'checked' : page['checked']}" [gaEvent]="{category: 'Favorites Feed', action: 'click', label: 'Select Favorite'}">
                <span class="far fa-circle" *ngIf="!page['checked']"></span>
                <span class="fas fa-check-circle check" *ngIf="page['checked']"></span>
            </div>
            <span class="date" [innerHTML]="page.changed"></span><br>
            <a class="header-5 inline-link" [innerHTML]="page.title" routerLink="/{{getURLString(page.field_category)}}/{{page.nid}}"
               [gaEvent]="gaContentEvent(page)"></a>
            <p class="body-copy" [innerHTML]="page.field_summary"></p>
        </div>
    </div>
    <div class="spotlight-holder" *ngIf="filter === 'recommended' && !loading">
        <div class="spotlight" *ngFor="let page of recommendedItems">
            <span class="date" [innerHTML]="page.changed"></span><br>
            <a class="header-5 inline-link" [innerHTML]="page.title" routerLink="/{{getURLString(page.field_category)}}/{{page.nid}}"
               [gaEvent]="gaContentEvent(page)"></a>
            <p class="body-copy" [innerHTML]="page.field_summary"></p>
        </div>
    </div>
    <div style="text-align: right" *ngIf="favoriteItems.length > 0 && filter !== 'recommended'">
        <button class="btn btn-special" *ngIf="editFavorites" (click)="deleteSelected()" [gaEvent]="{category: 'Favorites Feed', action: 'click', label: 'Delete Selected'}">Delete Selected</button>
        <button class="btn btn-grey" (click)="toggleEditFaves()" [gaEvent]="{category: 'Favorites Feed', action: 'click', label: 'Toggle Edit'}"><span *ngIf="!editFavorites">Edit</span><span *ngIf="editFavorites">Done</span></button>
    </div>
</div>
