<div class="sidebar-content">
  <h4>{{title}}</h4>
  <ul class="quick-links" *ngIf="!loading">
    <li *ngFor="let link of listing" (click)="toggleGroup($event, link)" [ngClass]="{'links': !link.group}">
      <div *ngIf="link.group">
        <div *ngIf="link.name === 'Pinned'">
          <span class="fas fa-thumbtack pinned-icon"></span><i class="fas fa-caret-down" style="float: right;"></i>
        </div>
        <div *ngIf="link.name !== 'Pinned'">
          {{link.name}}<i class="fas fa-caret-down" style="float: right;"></i>
        </div>
        <div class="sublinks">
          <div class="sublink" *ngFor="let item of link.group">
            <a [innerHTML]="stripContainingHTML(item.title)" routerLink="/{{getURLString(item.field_category)}}/{{item.nid}}"
               [gaEvent]="gaContentEvent(item)"></a>
          </div>
        </div>
      </div>
      <div *ngIf="!link.group">
        <a [innerHTML]="stripContainingHTML(link.title)" routerLink="/{{getURLString(link.field_category)}}/{{link.nid}}"
           [gaEvent]="gaContentEvent(link)"></a>
      </div>
    </li>
    <li class="no-results" *ngIf="(!listing || listing.length === 0) && !loading">Nothing to show.</li>
  </ul>
  <div style="padding: 0;" *ngIf="loading">
    <div class="quick-link-standin" *ngFor="let standin of standins"></div>
  </div>
</div>
