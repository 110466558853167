<div class="card resource-links">
  <h3><i class="fas fa-clock" style="margin-right: 12px;"></i>{{title}}
    <div class="header-tool-container" (click)="openCustomizeModal()" [gaEvent]="gaFilterModalEvent()">
      <span class="edit-recent-updates fas fa-cog header-tool-icon"></span>
      <span class="header-tool-text">Customize</span>
    </div>
    <div class="header-tool-container" (click)="copyHTML()" *ngIf="getNewsletterPermission() && feedItems" [gaEvent]="{category: 'Recent Updates Feed', action: 'click', label: 'Copy Email HTML'}">
      <span class="copy-html fas fa-spinner fa-spin header-tool-icon" *ngIf="newsletterLoading && !newsletterSuccessFlash"></span>
      <span class="copy-html fas fa-check header-tool-icon" *ngIf="newsletterSuccessFlash"></span>
      <span class="copy-html fas fa-envelope-open-text header-tool-icon" *ngIf="!newsletterLoading && !newsletterSuccessFlash"></span>
      <span class="header-tool-text">Newsletter</span>
    </div>
  </h3>
  <div class="news">
    <!-- Recent updates -->
    <div class="search-show">
      <div class="exposure-filter large-filter" [ngClass]="{'first': filter === '', 'second': filter === 'customer', 'third': filter === 'internal'}">
        <div (click)="selectFilter('')">All</div>
        <div (click)="selectFilter('customer')">Customer-facing</div>
        <div (click)="selectFilter('internal')">Internal</div>
      </div>
      <div class="search-filter recent-updates-filter">
        <select (change)="selectChanged($event)">
          <option value="seven_days">Last 7 days</option>
          <option value="thirty_days">Last 30 days</option>
        </select>
      </div>
    </div>
    <div>
      <app-news-item *ngFor="let item of feedItems" [ngClass]="{'hidden': item.field_confidentiality.toLowerCase().indexOf(filter) === -1}" [item]="item"></app-news-item>
    </div>
    <div class="load-more" (click)="loadMore()" *ngIf="!loading && feedItems.length > 0 && feedItems.length % 10 === 0">Load more</div>
    <div class="loader-fullpage-container" *ngIf="loading">
      <div class="loader-large"></div>
    </div>
    <div class="no-results" *ngIf="feedItems.length === 0 && !loading">Nothing to show.</div>
  </div>
</div>
