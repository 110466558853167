import {Component, OnInit, ViewChild, ElementRef, Renderer2} from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";
import {jsPDF} from "jspdf"
import html2canvas from "html2canvas";

@Component({
  selector: 'app-oem-cert-grid',
  templateUrl: './oem-cert-grid.component.html',
  styleUrls: ['./oem-cert-grid.component.scss']
})
export class OemCertGridComponent implements OnInit {

  @ViewChild('oemCertificationGrid') gridElement: ElementRef;

  filtersLoading: boolean;
  gridLoading: boolean;
  pdfLoading: boolean;
  isSelectAllChecked: boolean;
  oems = {};
  oemPrograms = [];
  departments = [];
  businessAreas = [];
  filteredOems = [];
  statusIconMap = {};
  programTypeId = null;
  url = window['ORIGIN_URL'];

  constructor(
    private drupalService: DrupalService,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {
  }

  ngOnInit() {
    this.gridLoading = true;
    this.isSelectAllChecked = false;
    this.getData();
  }

  getData() {
    this.filtersLoading = true;
    this.drupalService.getOems().then(async response => {
      this.oems = this.indexOems(response);
      this.selectAll();
      this.filtersLoading = false;
    }).finally(() => this.getPrograms());
  }

  async getPrograms() {
    const selectedFilters = Object.values(this.oems).map(oem => oem['nid']);
    Promise.all([
      this.drupalService.getGridPrograms(selectedFilters),
      this.drupalService.getTaxonomyTerms('blue-dot-department', 'field_display_in_certifications__value=1'),
      this.drupalService.getTaxonomyTerms('blue-dot-program-status'),
      this.drupalService.getTaxonomyTerms('blue-dot-business-area'),
      this.drupalService.getTaxonomyTerms('blue-dot-program-type')
    ]).then(response => {
      this.oemPrograms = response[0];
      this.filteredOems = this.oemPrograms;
      this.departments = this.sortDepartments(response[1], response[3]);
      this.businessAreas = this.setBusinessAreaIndexes(response[3], response[1]);
      this.createStatusIconMap(response[2]);
      const certType = response[4].filter(type => type.name.toLowerCase().includes('certification'));
      if (certType.length) {
        this.programTypeId = certType[0]['tid'];
      }
    }).finally(() => this.gridLoading = false);
  }

  indexOems(oems) {
    const indexedOems = {};
    oems.forEach(oem => indexedOems[oem.nid] = oem);
    return indexedOems;
  }

  sortDepartments(departments: any[], businessAreas) {
    let weights = {};
    businessAreas.forEach(area => weights[area.name] = parseInt(area.weight));
    return departments.sort((a, b) => weights[a.field_business_area.toLowerCase()] - weights[b.field_business_area.toLowerCase()]);
  }

  setBusinessAreaIndexes(businessAreas, departments) {
    let index = 0;
    let columnOffset = 3;
    let indexedBusinessAreas = [];
    for (let area of businessAreas) {
      let areaDepts = departments.filter(department => department.field_business_area === area.name);
      if ( !areaDepts.length ) {
        continue;
      }

      indexedBusinessAreas.push({
        tid: area.tid,
        name: area.name,
        departments: areaDepts,
        columnStart: index + columnOffset,
        columnEnd: index + areaDepts.length + columnOffset
      });

      index += areaDepts.length
    }

    return indexedBusinessAreas;
  }

  createStatusIconMap(statuses) {
    statuses.forEach(status => {
      let icon = 'far fa-circle';
      if (status.name.toLowerCase() === 'in program') {
        icon = 'fas fa-circle';
      } else if (status.name.toLowerCase() === 'coming soon') {
        icon = 'far fa-dot-circle';
      }
      this.statusIconMap[status['tid']] = icon;
    })
  }

  toggleFilter(oem) {
    oem.checked = !oem.checked;
  }

  selectAll() {
    this.isSelectAllChecked = !this.isSelectAllChecked;
    Object.values(this.oems).forEach(oem => oem['checked'] = this.isSelectAllChecked);
  }

  updateFilters() {
    this.filteredOems = this.oemPrograms.filter(oem => this.oems[oem.id].checked)
  }

  hasProgram(oem, deptId) {
    return oem.departments[deptId] && oem.departments[deptId][0] !== null;
  }

  getIconClass(oem, deptId) {
    let result = 'hidden';

    if (this.hasProgram(oem, deptId)) {
      let iconClass = this.statusIconMap[oem.departments[deptId][0]];
      if (iconClass) {
        result = iconClass;
      }
    }

    return result;
  }

  createPDF() {
    if (!this.gridLoading) {
      this.pdfLoading = true;
      try {
        //clone grid and scale to improve img resolution
        const gridClone = this.gridElement.nativeElement.cloneNode(true);
        this.renderer.setStyle(gridClone, 'position', 'absolute');
        this.renderer.setStyle(gridClone, 'transform', 'scale(3, 3) translate(-10000px, -10000px)');
        this.renderer.appendChild(this.el.nativeElement, gridClone);
        html2canvas(gridClone).then(canvas => {
          const grid = canvas.toDataURL('image/png')
          const pdf = new jsPDF('p', 'mm', 'a4');
          const pdfWidth = pdf.internal.pageSize.width;
          const pdfHeight = pdf.internal.pageSize.height;
          const gridWidth = pdfWidth;
          const gridHeight = canvas.height / canvas.width * gridWidth;
          const numPages = Math.ceil(gridHeight / pdfHeight);
          for (let i = 0; i < numPages; i++) {
            let position = -1 * pdfHeight * i
            if (i > 0) {
              pdf.addPage();
            }
            pdf.addImage(grid, 'PNG', 0, position, gridWidth, gridHeight);
          }
          pdf.save('oem-certification-grid.pdf');
          this.renderer.removeChild(this.el.nativeElement, gridClone); //destroy clone
          this.pdfLoading = false;
        });
      } catch (e) {
        this.pdfLoading = false;
      }
    }
  }
}
