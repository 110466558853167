<div class="loader-fullpage-container pdf-loader-container" *ngIf="pdfLoading">
  <div class="loader-large"></div>
</div>

<div class="main-with-sidebar-grid">
  <div class="article-grid">
    <div class="col-left">
    </div>
    <div class="col-middle-three">
      <app-breadcrumbs [title]="'OEM Certifications Grid'" [category]="'OEM Info'"></app-breadcrumbs>
      <!--      Filters Row      -->
      <ng-container *ngIf="!filtersLoading">
        <div style="display:flex;">
          <div class="filter-grid">
            <ng-container *ngFor="let oem of oems | keyvalue">
              <div class="filter-wrapper" (click)="toggleFilter(oem.value)">
                <i [ngClass]="oem.value.checked ? 'fas fa-check-square' : 'far fa-square'"></i>
                <p>{{oem.value.title}}</p>
              </div>
            </ng-container>
          </div>
          <div class="filter-buttons">
            <button (click)="updateFilters()">Update</button>
            <div class="filter-wrapper" (click)="selectAll()">
              <i [ngClass]="isSelectAllChecked ? 'fas fa-check-square' : 'far fa-square'"></i>
              <p>Select All</p>
            </div>
            <div class="inline-link" (click)="createPDF()">Export to PDF</div>
          </div>
          <div class="key-wrapper">
            <p class="header-4 key-header">OEM Grid Icons</p>
            <div class="key-line">
              <i class="fas fa-circle"></i>
              <p>In Program</p>
            </div>
            <div class="key-line">
              <i class="far fa-dot-circle"></i>
              <p>Coming Soon</p>
            </div>
            <div class="key-line">
              <i class="far fa-circle"></i>
              <p>Not In Program</p>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- End Filters Row -->
      <!-- Grid -->
      <ng-container *ngIf="!gridLoading && businessAreas.length">
        <div #oemCertificationGrid class="grid-wrapper grid"
             [ngStyle]="{'grid-template-columns': '150px 30px repeat(' + departments.length + ', 1fr)'}">
          <!-- Business Area Header -->
          <ng-container *ngFor="let ba of businessAreas; index as baIndex; count as baCount;">
            <div [ngClass]="{
                   'grid-cell ba-header': true,
                   'blue': baIndex % 3 === 0,
                   'red': baIndex % 3 === 1,
                   'green': baIndex % 3 === 2
                 }"
                 [ngStyle]="{'grid-column': ba['columnStart'] + '/' + ba['columnEnd']}">
              <p>{{ ba.name }}</p>
            </div>
          </ng-container>
          <!-- End Business Area Header -->
          <!-- Department Header -->
          <ng-container *ngFor="let ba of businessAreas; index as baIndex; count as baCount">
            <ng-container *ngFor="let dept of ba['departments']; index as deptIndex; first as isFirstDept">
              <div [ngClass]="{
                     'grid-cell department-header': true,
                     'cell-border-left': !isFirstDept,
                     'blue': baIndex % 3 === 0,
                     'red': baIndex % 3 === 1,
                     'green': baIndex % 3 === 2
                   }"
                   [ngStyle]="{'grid-column-start': ba['columnStart'] + deptIndex}">
                <p>{{dept.name}}</p>
              </div>
            </ng-container>
          </ng-container>
          <!-- End Department Header -->
          <!-- OEM Row -->
          <ng-container *ngFor="let oem of filteredOems; index as oemIndex; even as isEven">
            <div class="grid-cell header-4 oem-title" [ngStyle]="{'grid-row-start': oemIndex + 3}">
              <p>{{oem.title}}</p>
            </div>
            <div class="grid-cell program-type">
              <div [ngClass]="{'grid-color-wrapper-type': true, 'shaded': isEven}">
                <p>Certifications</p>
              </div>
            </div>
            <!-- OEM Cell -->
            <ng-container *ngFor="let ba of businessAreas; index as baIndex; count as baCount">
              <ng-container *ngFor="let dept of ba['departments']; first as isFirstDept">
                <ng-container *ngIf="hasProgram(oem, dept['tid'])">
                  <a [href]="[url + 'oem/' + oem.id + '?department=' + dept.tid + '&ba=' + ba.tid + '&type=' + programTypeId]" target="_blank"
                     [ngClass]="{
                        'grid-cell blue-dot-cell': true,
                        'cell-border-left': !isFirstDept,
                        'blue': baIndex % 3 === 0,
                        'red': baIndex % 3 === 1,
                        'green': baIndex % 3 === 2,
                    }">
                    <div [ngClass]="{'grid-color-wrapper': true, 'shaded': isEven}">
                      <i [ngClass]="getIconClass(oem, dept['tid'])"></i>
                    </div>
                  </a>
                </ng-container>
                <ng-container *ngIf="!hasProgram(oem, dept['tid'])">
                  <div [ngClass]="{
                          'grid-cell blue-dot-cell': true,
                          'cell-border-left': !isFirstDept,
                          'blue': baIndex % 3 === 0,
                          'red': baIndex % 3 === 1,
                          'green': baIndex % 3 === 2,
                        }">
                    <div [ngClass]="{'grid-color-wrapper': true, 'shaded': isEven}">
                      <i [ngClass]="getIconClass(oem, dept['tid'])"></i>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
            <!-- End OEM Cell -->
          </ng-container>
          <!-- End OEM Row -->
        </div>
      </ng-container>
      <ng-container *ngIf="!gridLoading && !businessAreas.length">
        <h4 style="margin-top: 50px;">No information available at this time. Please check back later.</h4>
      </ng-container>
      <!-- End Grid -->
    </div>
  </div>
</div>
