<div class="card resource-links">
  <h3><i class="fas fa-clock" style="margin-right:12px;"></i>{{title}}</h3>
  <div class="no-results" *ngIf="feedItems.length === 0 && !loading">Nothing to show.</div>

  <!-- Stand-ins -->
  <div *ngIf="loading">
    <div class="spotlight" *ngFor="let page of standins; let i = index">
      <div class="standin-link"></div>
      <div class="standin-body-copy"></div>
    </div>
  </div>

  <!-- Related News -->
  <div *ngIf="!loading">
    <div class="spotlight" *ngFor="let item of feedItems; let i = index">
      <span class="date" [innerHTML]="item.changed"></span><br>
      <a class="header-5 inline-link" [innerHTML]="stripContainingHTML(item.title)" routerLink="/{{getURLString(item.field_category)}}/{{item.nid}}"
         [gaEvent]="gaContentEvent(item)"></a>
      <p class="body-copy" [innerHTML]="stripContainingHTML(item.field_summary)"></p>
    </div>
  </div>
</div>
