import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {GoogleAnalyticsService} from "../google-analytics/google-analytics.service";
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public profileSet = true;
  public redirectUrl = '';
  public hasNewsletterPermission = true;
  public authenticating;
  public directReports: [] = [];

  private user;
  private verifyingCookie = false;
  private currentAsyncVerify;

  private completeUserSource = new BehaviorSubject<boolean>(false);
  completeUser$ = this.completeUserSource.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
  }

  /**
   * Verifies that the user has a valid cookie and they are authorized
   */
  verify() {
    this.verifyCookie();
    return this.currentAsyncVerify.then((userResponse: any) => {
      this.verifyingCookie = false;
      if (this.user !== userResponse) {
        this.user = userResponse;
        this.completeUserSource.next(true);
      }
      this.getRoles();
      return true;
    }, () => {
      this.navigateToLogout();
      return false;
    });
  }

  getRoles() {
    // Drupal role request
    const url = `${window['BASE_URL']}user_details?name=${this.user}&_format=json`;
    this.http.get(url, {withCredentials: true}).pipe(tap(value => value, () => {
      this.authenticating = false;
      this.navigateToLogout();
    })).subscribe(response => {
      const gaRole = this.googleAnalyticsService.mapUserRoles(response[0]['roles_target_id']);
      this.googleAnalyticsService.setUserProperties(gaRole);
      this.evaluatePermissions(response);
      this.getDirectReports();
    });
  }

  /**
   * Retrieves the users reports from the css webservice
   */
  getDirectReports() {
    this.getUser().then(user => {
      const url = `${window['BASE_URL']}employee_webservice/v1/getReports/${user}`;
      this.http.get(url, {withCredentials: true}).subscribe(response => {
        this.directReports = JSON.parse(JSON.stringify(response))
      }, error => console.error(error));
    })
  }

  /**
   * Evaluate returned promise to determine user permissions and profiles
   * @param response
   */
  evaluatePermissions(response: Object) {
    this.hasNewsletterPermission = false;
    if (response[0]) {
      const roles = response[0]['roles_target_id'].toLowerCase();
      this.hasNewsletterPermission = roles.indexOf('[wws]') !== -1 || roles.indexOf('administrator') !== -1;
      this.profileSet = !!response[0]['field_wws_personalization'];
      if (!this.profileSet) {
        this.router.navigate(['/setProfile']);
      }
    }
    if (this.router.url === '/login') {
      this.router.navigate(['/']);
    }
  }

  /**
   * Sends verify request for cookie
   */
  verifyCookie() {
    this.verifyingCookie = true;
    this.currentAsyncVerify = this.http.get(`${window['BASE_URL']}ldap_user`, {
      responseType: 'text',
      withCredentials: true
    })
      .pipe(tap(value => value, () => this.verifyingCookie = false)).toPromise();
  }

  /**
   * Returns true if the user is resolved, or a promise if it has not been resolved
   */
  async isAuth() {
    if (this.user && this.user !== '') {
      return true;
    } else {
      return await this.verify();
    }
  }

  /**
   * Returns user or returns promise thereof
   */
  async getUser() {
    if (this.verifyingCookie) {
      return await this.currentAsyncVerify.then(response => (response !== 403) ? response : "");
    } else {
      return new Promise(resolve => resolve(this.user ? this.user : ""));
    }
  }

  /**
   * Validates against LDAP and saves username and encrypts and saves password
   * @param user
   * @param password
   * @param successCallback
   * @param failCallback
   */
  login(user: string, password: string, successCallback: any, failCallback: any) {
    this.authenticating = true;
    this.authenticate(user, btoa(password)).subscribe(() => {
      this.user = user;
      this.authenticating = false;
      this.completeUserSource.next(true);
      this.verify();
      successCallback();
    }, () => {
      this.authenticating = false;
      this.completeUserSource.next(false);
      failCallback();
    })
  }

  /**
   * Authenticate user against LDAP in Drupal
   * @param user
   * @param password
   */
  authenticate(user: string, password: string) {
    const formData = new FormData();
    formData.append('username', user);
    formData.append('password', password);
    return this.http.post(`${window['BASE_URL']}ldap_login`, formData, {withCredentials: true});
  }

  /**
   * Sends request to clear cookie
   */
  clearCookie() {
    this.http.get(`${window['BASE_URL']}user/logout`, {withCredentials: true}).subscribe();
  }

  navigateToLogout() {
    this.user = null;
    this.router.navigate(['login']);
  }

  /**
   * Clear auth info
   */
  logout() {
    this.clearCookie();
    this.navigateToLogout();
  }
}
