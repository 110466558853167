import { Injectable } from '@angular/core';

declare const Vimeo: any; // Import the Vimeo Player API

@Injectable({
  providedIn: 'root',
})
export class VimeoService {
  player: any;

  constructor() {
  }

  initVimeoPlayer() {
    const iframe = document.getElementById('vimeo-frame');
    this.player = new Vimeo.Player(iframe);
  }
}
