<div class="main-with-sidebar" (window:resize)="onResize($event)">
  <div class="article-grid">
    <div class="col-left">
      <ng-container *ngIf="resolvedPage">
        <app-sidebar [category]="resolvedPage.field_category"></app-sidebar>
      </ng-container>
    </div>
    <div class="col-middle-three">
      <ng-container *ngIf="resolvedPage">
        <app-breadcrumbs [title]="resolvedPage.title" [category]="resolvedPage.field_category"></app-breadcrumbs>

        <ng-container *ngIf="!loading">
          <div class="page-toolbar">
            <div class="page-favorite page-tool" (click)="toggleFavorite()">
              <i #favoriteStar class="fa-star favorite-star" [ngClass]="{'far': !isFavorite, 'fas': isFavorite}"></i>
              <ng-container *ngIf="isFavorite">
                <span [gaEvent]="{event_name: 'RemoveFavorite', title: resolvedPage.title, nid: resolvedPage.nid}">Remove favorite</span>
              </ng-container>
              <ng-container *ngIf="!isFavorite">
                <span [gaEvent]="{event_name: 'AddFavorite', title: resolvedPage.title, nid: resolvedPage.nid}">Add favorite</span>
              </ng-container>
            </div>
            <ng-container *ngIf="hasReports()">
              <div class="page-favorite page-tool"
                   [gaEvent]="{category: 'Favorite Toggle', action: 'click', label: resolvedPage.title}"
                   (click)="recommendPage()">
                <i class="fa-users fas"></i>Recommend Page
              </div>
            </ng-container>
          </div>

          <div class="article-text">
            <h1 [innerHTML]="resolvedPage.title"></h1>
            <!-- Article No Media -->
            <ng-container *ngIf="resolvedPage['field_media'] === '' && resolvedPage['field_oem_page'] !== 'True'">
              <div class="body-copy" [innerHTML]="resolvedPage.body"></div>
            </ng-container>
            <!-- Article With Media -->
            <ng-container *ngIf="resolvedPage['field_media'] !== '' && resolvedPage['field_oem_page'] !== 'True'">
              <div class="media-container">
                <div class="body-copy" [innerHTML]="resolvedPage.field_summary"></div>
                <app-media [page]="resolvedPage" [media]="resolvedPage.field_media"></app-media>
              </div>
            </ng-container>
            <!-- OEM Program Page -->
            <ng-container *ngIf="resolvedPage['field_oem_page'] === 'True'">
              <app-oem-info [page]="resolvedPage"></app-oem-info>
            </ng-container>
          </div>

          <ng-container *ngIf="hasRelated && smallHomeSize">
            <div class="resource-links-small small">
              <app-resources [feedItems]="relatedPages" [loading]="resourcesLoading"></app-resources>
              <ng-container *ngIf="resolvedPage['field_oem_page'] === 'True'">
                <app-oem-icon-legend></app-oem-icon-legend>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="smallHomeSize && !hasRelated && resolvedPage['field_oem_page'] === 'True'">
            <div class="resource-links-small small">
              <app-oem-icon-legend></app-oem-icon-legend>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="!loading && resolvedPage && (hasRelated || resolvedPage['field_oem_page'] === 'True')">
      <div class="col-right big">
        <ng-container *ngIf="hasRelated">
          <app-resources [feedItems]="relatedPages" [loading]="resourcesLoading"></app-resources>
        </ng-container>
        <ng-container *ngIf="resolvedPage['field_oem_page'] === 'True'">
          <app-oem-icon-legend></app-oem-icon-legend>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <app-recommend-modal></app-recommend-modal>
</div>
