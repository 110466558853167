<div>
  <header class="wws-header top">
    <div style="position: relative;">
      <div class="wws-logo" (click)="goTo('home')" [gaEvent]="{category: 'Main Nav', action: 'click', label: 'Home'}">
        What We Sell
      </div>
      <div class="wws-header-info" *ngIf="username">
        <div class="wws-header-right user-info-large">
          <dropdown [id]="'tools-dropdown'" [iconClass]="'toolbox'" [title]="'tools'"
                    [options]="toolDropdownOptions"></dropdown>
        </div>
        <div class="wws-header-right user-info-large">
          <dropdown [id]="'user-dropdown'" [iconClass]="'user'" [title]="username"
                    [options]="userDropdownOptions"></dropdown>
        </div>
      </div>
    </div>
  </header>
  <header class="wws-header bottom" *ngIf="username">
    <div #mobileButtonMenu class="mobile-wws-button-menu">
      <div class="mobile-header-item" (click)="toggleMobileButtonMenu()">
        <i class="fas fa-bars"></i>
      </div>
      <div class="mobile-search-holder">
        <input type="text" class="wws-header-search header-search" placeholder="Search ..." [(ngModel)]="query"
               (keydown)="handleKeyboardEvent($event)">
        <button class="wws-search-btn" (click)="search()"></button>
      </div>
    </div>
    <div #buttonMenu class="wws-button-menu">
      <div class="wws-header-item" (click)="goTo('home')"
           [gaEvent]="{ event_name: 'TabBarPress', screen_pressed: 'Home'}">
        <i class="fas fa-home header-icon"></i>
        <div class="header-title">Home</div>
      </div>
      <div class="wws-header-item" *ngFor="let category of categories" (click)="goTo(category.name)"
           [gaEvent]="{event_name: 'TabBarPress', screen_pressed: category.name}">
        <i class="fas header-icon" [ngClass]="category.icon"></i>
        <div class="header-title" [innerHTML]="category.name"></div>
      </div>
      <div class="wws-header-item mobile" (click)="editProfile()">
        <i class="fas fa-cog header-icon" style="color: white !important"></i>
        <div class="header-title">Edit Profile</div>
      </div>
      <div class="wws-header-item mobile" (click)="logout()">
        <i class="fas fa-sign-out-alt header-icon" style="color: white !important"></i>
        <div class="header-title">Sign Out</div>
      </div>
    </div>
    <div class="search-holder">
      <input type="text" class="wws-header-search header-search" placeholder="Search ..." [(ngModel)]="query"
             (keydown)="handleKeyboardEvent($event)">
      <button class="wws-search-btn" (click)="search()"></button>
    </div>
    <div class="tiny tiny-search" (click)="search()">
      <i class="fas fa-search" style="vertical-align: sub; font-size: 16px;"></i>
    </div>
  </header>
</div>
