import {Component, Input, OnInit, Renderer2} from '@angular/core';
import { DrupalService } from '../../service/drupal/drupal.service';
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../../service/google-analytics/google-analytics.service";
import { StringService } from '../../service/string/string.service';
import { FilterService } from '../../service/filter/filter.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() category;
  listing: any[];
  loading = false;
  standins = new Array(6);
  title: string;

  constructor(
    private drupalService: DrupalService,
    private filterService: FilterService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.loading = true;
    this.getPageData();
  }

  async getPageData() {
    try {
      this.title = await this.category;
      const type = StringService.getURLString(this.title);
      const filters = await this.filterService.getFilters(['organization', 'language', 'locale']);
      const query = this.filterService.getQuery(filters);
      const typeListing = await this.drupalService.getTerms(type.substring(0, type.length - 1), query);
      // Sort entries
      if (this.title.toLowerCase() !== 'news') {
        this.listing = this.sort(typeListing);
      } else {
        this.listing = typeListing;
      }
    }
    catch (err) { console.log(err); }
    finally { this.loading = false; }
  }

  stripContainingHTML(string) {
    return StringService.stripContainingHTML(string);
  }

  getURLString(string) {
    return StringService.getURLString(string);
  }

  /**
   * Takes a list of alphabetically sorted items and groups them into similarly sized chunks of alphabetically adjacent items
   * @param list
   * @returns {any[]}
   */
  sort(list) {
    let size = Math.ceil(list.length / 8);
    // Sort into alphabetical groups
    let sorted = this.alphabetize(list);
    // Grab pinned to top items
    let pinned = list.filter(item => item.field_sticky === 'True');
    // Group into chunks of similar size
    let grouped = [];
    let holder = [];
    let letters = [];
    for (let property in sorted) {
      if (sorted.hasOwnProperty(property)) {
        if (holder.length === 0) {
          holder = holder.concat(sorted[property]);
          letters.push(property);
        } else if (holder.length + sorted[property].length <= size) {
          holder = holder.concat(sorted[property]);
          letters.push(property);
        } else {
          grouped.push({name: this.getNameFromLetters(letters), group: holder});
          holder = sorted[property];
          letters = [property];
        }
      }
    }
    if (holder.length > 0) {
      grouped.push({name: this.getNameFromLetters(letters), group: holder});
    }
    if (pinned.length > 0) {
      grouped.unshift({name: 'Pinned', group: pinned});
    }
    return grouped;
  }

  /**
   * Takes an array of nodes and sorts them into an object of alphabetized arrays containing their respective entries
   * @param list
   */
  private alphabetize(list) {
    let sorted = {};
    let count = 0;
    for (let item of list) {
      if (item.field_sticky !== 'True') {
        let letter = StringService.stripContainingHTML(item.title).charAt(0).toUpperCase();
        if (!sorted.hasOwnProperty(letter)) {
          sorted[letter] = [];
        }
        sorted[letter].push(item);
        count++;
      }
    }
    return sorted;
  }

  /**
   * Create a group name from the given letters it spans
   * @param letters
   * @returns {any}
   */
  getNameFromLetters(letters) {
    let name = letters[0];
    if (letters.length > 1) {
      name += '-' + letters[letters.length - 1];
    }
    return name;
  }

  // Helper DOM method to open a sidebar group
  toggleGroup(event: any, item: any) {
    if (event.target.tagName.toLowerCase() === 'a' || event.target.classList.contains('sublink')) {
      return;
    }

    if (event.currentTarget.classList.contains('open')) {
      this.renderer.removeClass(event.currentTarget, 'open');
    } else {
      this.renderer.addClass(event.currentTarget, 'open');
      this.googleAnalyticsService.sendAction('AccordionExpansion', {
        event_name: 'AccordionExpansion',
        title: item.name,
        source: this.router.url
      });
    }
  }

  gaContentEvent(item) {
    return {
      event_name: 'Content',
      nid: item.nid,
      source: this.router.url,
      root_screen: item.field_category,
      title: item.title,
      media_title: item.field_media?.split('^')[0],
      content_type: item.field_type,
      advanced_content_type: item.field_advanced_search,
      organizations: item.field_organization_tids,
      locations: item.field_locales ? item.field_locales : item.field_locale,
      searching: false
    };
  }
}
