import {Injectable} from '@angular/core';
import {DrupalService} from '../drupal/drupal.service';
import {Recommended} from './recommended';
import {AuthService} from "../auth/auth.service";

@Injectable({
    providedIn: 'root'
})
export class FavoritesService {

    public updatingFavorites = false;
    public updatingFavoritesAsync;

    public updatingRecommended = false;
    public updatingRecommendedAsync;

    constructor(
        private drupalService: DrupalService,
        private authService: AuthService
    ) {
    }

    /**
     * Returns array of ids of user's favorites
     * @returns {Promise<Array<any>>}
     */
    async getFavorites() {
        if (this.updatingFavorites) {
            await this.updatingFavoritesAsync;
            return this.getFavoritesHelper();
        } else {
            return this.getFavoritesHelper();
        }
    }

    private async getFavoritesHelper() {
        const userInfo = await this.drupalService.getUserInfo();
        const favorites = userInfo[0]['field_wws_favorites'];
        return favorites ? favorites.split(',') : [];
    }

    async getRecommended() {
        if (this.updatingRecommended) {
            await this.updatingRecommendedAsync;
        }

        return this.getRecommendedHelper();
    }

    private async getRecommendedHelper() {
        const userInfo = await this.drupalService.getUserInfo();
        return this.parseRecommendations(userInfo[0]['field_wws_recommended']);
    }

    public parseRecommendations(reco) {
        if (reco && reco.length > 0) {
            reco = reco.replace(/&quot;/g, "\"");
            try { // Check if valid json
                reco = JSON.parse(reco.replace(/&quot;/g, "\""));
                return new Recommended(reco.leaderId, reco.recommendations);
            } catch (e) { // If not, then using old format
                reco = reco.split(",");
                for (let r of reco) {
                    r = r.toString();
                }
                return new Recommended("", reco);
            }
        } else {
            return new Recommended("", []);
        }
    }

    /**
     * Patches user's favorites with given array
     * @param faves
     */
    setFavorites(faves) {
        let stringFaves = this.removeDuplicates(faves).join();
        this.updatingFavorites = true;
        this.updatingFavoritesAsync = this.drupalService.getUserInfo().then(async user_info => {
            await this.drupalService.patchFavorites(stringFaves, user_info[0]['uid']);
            this.updatingFavorites = false;
        });
    }

    removeDuplicates(faves) {
        let holder = {};
        let finalFaves = [];
        for (let fave of faves) {
            if (!holder[fave]) {
                holder[fave] = true;
                finalFaves.push(fave);
            }
        }

        return finalFaves;
    }

    /**
     * Add's a favorite to the user's favorites and patches
     * @param fave
     */
    addFavorite(fave) {
        this.getFavorites().then(faves => {
            faves.push(fave);
            this.setFavorites(faves);
        });
    }

    /**
     * Removes favorite and patches
     * @param nid
     */
    removeFavorite(nid) {
        let id = nid.toString();
        this.getFavorites().then(favorites => {
            let newFavorites = [];
            for (let fave of favorites) {
                if (fave !== id) {
                    newFavorites.push(fave);
                }
            }
            this.setFavorites(newFavorites);
        });
    }

    /**
     * Returns true if the given id is in the array of user favorites
     * @param nid
     * @param username
     * @returns {Promise<boolean>}
     */
    isFavorite(nid) {
        let id = nid.toString();
        return this.getFavorites().then(favorites => {
            for (let fave of favorites) {
                if (fave === id) {
                    return true;
                }
            }
            return false;
        });
    }

}
