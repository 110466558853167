export const NEWSLETTER_TEMPLATE =
    "<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.0 Transitional//EN\" \"http://www.w3.org/TR/REC-html40/loose.dtd\">\n" +
    "<html>\n" +
    "  <head>\n" +
    "    \n" +
    "  </head>\n" +
    "  <body>\n" +
    "    <table style=\"width:720px;border-collapse:collapse\">\n" +
    "      <tr>\n" +
    "        <th style='padding:14px;font-size:1em;background:#384053;color:white;font-family:\"Trebuchet MS\", Helvetica, sans-serif;font-weight:600;text-align:left;border:1px solid #384053;'>Recent Updates</th>\n" +
    "      </tr>" +
    "%%item%%" +
    "\t</table>\n" +
    "</body>\n" +
    "</html>";

export const NEWSLETTER_ITEM =
    "<tr>\n" +
    "<td class=\"news-item\" style=\"padding:24px;border:1px solid #ddd;margin-bottom:12px;background:white;\">\n" +
    "<span class=\"date\" style='font-family:\"Trebuchet MS\", Helvetica, sans-serif;font-size:0.75em;color:#919397;'>%%date%%</span>\n" +
    "<div class=\"header-4\" style='font-size:1.2em;color:#384053;margin:5px 0;font-weight:600;font-family:\"Palatino Linotype\", \"Book Antiqua\", Palatino, serif;margin:4px 0 !important;padding:0;'>%%title%%</div>\n" +
    "<div class=\"body-copy\" style='font-family:\"Trebuchet MS\", Helvetica, sans-serif;font-size:0.9em;letter-spacing:0.55px;color: #697486;padding:0 14px;'>%%body%%</div>\n" +
    "<div class=\"news-footer\" style=\"padding:14px 4px;margin-top:10px;\">\n" +
    "<div class=\"news-continue\">\n" +
    "<a href=\"%%origin%%%%cat%%%%nid%%\" style=\"color:#1E88E5\">Continue Reading</a><span style=\"color:#1E88E5\"> > </span>\n" +
    "</div>\n" +
    "</div>\n" +
    "</td>\n" +
    "</tr>";
