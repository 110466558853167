import {BrowserModule} from '@angular/platform-browser';
import {ElementRef, NgModule, Renderer2} from '@angular/core';
import {AppComponent} from './app.component';
import {HashLocationStrategy, LocationStrategy, DatePipe} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './home/home.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {ContentComponent} from './content/content.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {DrupalService} from '../service/drupal/drupal.service';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import {AuthService} from '../service/auth/auth.service';
import {GuardService} from '../service/guard/guard.service';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {ListComponent} from './list/list.component';
import {StringService} from '../service/string/string.service';
import {FavoritesService} from '../service/favorites/favorites.service';
import {CustomizeModalComponent} from './customize-modal/customize-modal.component';
import {MediaComponent} from './media/media.component';
import {SearchComponent} from './search/search.component';
import {FeedModule} from '../feed/feed.module';
import {NewsletterService} from '../service/newsletter/newsletter.service';
import {NotFoundComponent} from './not-found/not-found.component';
import {ClickOutsideDirective} from '../directive/click-outside/click-outside.directive';
import {RouteResolverService} from '../service/route-resolver/route-resolver.service';
import {FilterComponent} from './filter/filter.component';
import {PersonalizationService} from '../service/personalization/personalization.service';
import {ProfileModalComponent} from './profile-modal/profile-modal.component';
import {DropdownComponent} from './input/dropdown/dropdown.component';
import {DropdownMultiselectComponent} from './input/dropdown-multiselect/dropdown-multiselect.component';
import {RecommendModalComponent} from './recommend-modal/recommend-modal.component';
import {OemInfoComponent} from './oem-info/oem-info.component';
import {GoogleAnalyticsService} from '../service/google-analytics/google-analytics.service';
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {OemIconLegendComponent} from './oem-icon-legend/oem-icon-legend.component';
import {HomeRedirectComponent} from './home-redirect/home-redirect.component';
import {VimeoService} from "../service/vimeo/vimeo.service";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { OemCertGridComponent } from './oem-cert-grid/oem-cert-grid.component';
import {OemGridGuardService} from "../service/oem-grid-guard/oem-grid-guard.service";

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        ContentComponent,
        SidebarComponent,
        LoginComponent,
        BreadcrumbsComponent,
        NotFoundComponent,
        ListComponent,
        CustomizeModalComponent,
        ProfileModalComponent,
        MediaComponent,
        SearchComponent,
        NotFoundComponent,
        ClickOutsideDirective,
        FilterComponent,
        DropdownComponent,
        DropdownMultiselectComponent,
        RecommendModalComponent,
        OemInfoComponent,
        OemIconLegendComponent,
        HomeRedirectComponent,
        OemCertGridComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FeedModule,
        FormsModule,
        BrowserAnimationsModule,
        NgbModule,
        ToastrModule.forRoot()
    ],
    providers: [
        DrupalService,
        FavoritesService,
        PersonalizationService,
        NewsletterService,
        RouteResolverService,
        AuthService,
        StringService,
        GuardService,
        OemGridGuardService,
        GoogleAnalyticsService,
        VimeoService,
        DatePipe,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
