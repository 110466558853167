<div (window:resize)="onResize($event)">
  <div *ngIf="belowContent" class="oem-icon-legend-wrapper">
    <hr>
    <h1>OEM Program Icons</h1>
    <div class="icon-row">
      <i class="fas fa-history fa-center"></i>
      <p>Recently Updated</p>
    </div>
    <div class="icon-row">
      <i class="fas fa-user-secret fa-center"></i>
      <p>Competitive Information Available</p>
    </div>
    <div class="icon-row">
      <i class="fas fa-question-circle fa-center"></i>
      <p>FAQs Available</p>
    </div>
    <div class="icon-row">
      <i class="fas fa-circle fa-center"></i>
      <p>In Program</p>
    </div>
    <div class="icon-row">
      <i class="far fa-dot-circle fa-center"></i>
      <p>Coming Soon</p>
    </div>
    <div class="icon-row">
      <i class="far fa-circle fa-center"></i>
      <p>Not In Program</p>
    </div>
  </div>

  <div *ngIf="!belowContent" class="oem-icon-legend-wrapper">
    <hr>
    <h3>OEM Program Icons</h3>
    <div class="icon-keys-large">
      <div class="icon-row">
        <i class="fas fa-history fa-center"></i>
        <p>Recently Updated</p>
      </div>
      <div class="icon-row">
        <i class="fas fa-user-secret fa-center"></i>
        <p>Competitive Information Available</p>
      </div>
      <div class="icon-row">
        <i class="fas fa-question-circle fa-center"></i>
        <p>FAQs Available</p>
      </div>
      <div class="icon-row">
        <i class="fas fa-circle fa-center"></i>
        <p>In Program</p>
      </div>
      <div class="icon-row">
        <i class="far fa-dot-circle fa-center"></i>
        <p>Coming Soon</p>
      </div>
      <div class="icon-row">
        <i class="far fa-circle fa-center"></i>
        <p>Not In Program</p>
      </div>
    </div>
  </div>
</div>
