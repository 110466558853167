import {Component, Input, OnInit} from '@angular/core';
import {StringService} from '../../service/string/string.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
})
export class NewsItemComponent  {

  @Input() item;
  constructor(private router: Router) { }

  stripContainingHTML(string){
      return StringService.stripContainingHTML(string);
  }

  getURLString(item){
      return StringService.getURLString(item);
  }

  gaContentEvent(item) {
    return {
      event_name: 'Content',
      nid: item.nid,
      source: this.router.url,
      root_screen: item.field_category,
      title: item.title,
      media_title: item.field_media?.split('^')[0],
      content_type: item.field_type,
      advanced_content_type: item.field_advanced_search,
      organizations: item.field_organization_tids,
      locations: item.field_locales ? item.field_locales : item.field_locale,
      searching: false
    };
  }

}
