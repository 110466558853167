<div class="expand-toggle-container">
    <input class="hidden" type="checkbox" id="{{id}}">
    <label class="expand-toggle" for="{{id}}" [title]="title">
        <span class="dropdown_arrow"></span>
        <span class="expand-toggle-icon fas fa-{{iconClass}}"></span>
        <span class="expand-toggle-text" [innerHTML]="title"></span>
        <ul class="expand-toggle-choices">
            <li *ngFor="let option of options" (click)="option.resolve()"><span class="fas fa-{{option.icon}}"></span><span [innerHTML]="option.name"></span></li>
        </ul>
    </label>
</div>
