import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringService {

  constructor() { }

  static getURLString(string) {
    string = string.toLowerCase();
    switch (string) {
      case 'oem info':
        return 'oem/';
      case 'sales tools':
        return 'sales/';
      case 'products':
        return 'product/';
      default:
        return string + '/';
    }
  }

  static getStringFromURL(url) {
    switch (url.toLowerCase()) {
      case 'competitive':
        return 'Competitive';
      case 'oem':
        return 'OEM Info';
      case 'sales':
        return 'Sales Tools';
      case 'product':
        return 'Products';
      default:
        return (url[0].toUpperCase() + url.substr(1));
    }
  }

  static getStringIcon(string) {
    string = string.toLowerCase();
    switch (string) {
      case 'news':
        return 'fa-newspaper';
      case 'products':
        return 'fa-list-alt';
      case 'oem info':
        return 'fa-info-circle';
      case 'competitive':
        return 'fa-address-book';
      case 'sales tools':
        return 'fa-folder-open';
      case 'industry':
        return 'fa-briefcase';
      default:
        return 'fa-list-alt';
    }
  }

  static stripContainingHTML(string) {
    let div = document.createElement('div');
    div.innerHTML = string;
    return div.textContent || div.innerText || "";
  }

  static makeFieldName(string) {
    return string.toLowerCase().replace(/ /g, '_').replace(/-/g, '_');
  }

  static makeTitleFromFieldName(string) {
    return string[0].toUpperCase() + string.substring(1).replace(/_/g, ' ');
  }

}
