<a [href]="resource_url" target="_blank" class="fullpage-link" *ngIf="type === 'pdf'"
   [gaEvent]="{category: 'PDF Media', action: 'click', label: 'Fullscreen'}">Click here to view fullpage</a>
<div class="pdf" [ngStyle]="{'display': type === 'pdf' ? 'block' : 'none'}">
  <embed type="application/pdf" [src]="resource_url"/>
</div>
<div class='video' [ngStyle]="{'display': type === 'video' ? 'block' : 'none'}">
  <div class="video-holder">
    <iframe id="vimeo-frame" [src]="resource_url" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
  </div>
  <div class="transcript" [innerHTML]="transcript"></div>
</div>
<div class='audio' *ngIf="type === 'audio'">
  <h4>{{title}}</h4>
  <audio #audioElement controls
         (timeupdate)="audioEvent($event)"
         (seeked)="audioEvent($event)"
         (play)="audioEvent($event)"
         (pause)="audioEvent($event)"
         (ended)="audioEvent($event)"
  >
    <source [src]="resource_url">
  </audio>
  <div class="transcript" [innerHTML]="transcript"></div>
</div>
<div class="file" *ngIf="type === 'xl' || type === 'ppt' || type === 'word'">
  <a [href]="resource_url" download class="download-link"
     [gaEvent]="{category: 'Media Download', action: 'click', label: title}">
    <i class="fas fa-file-excel" *ngIf="type === 'xl'"></i>
    <i class="fas fa-file-word" *ngIf="type === 'word'"></i>
    <i class="fas fa-file-powerpoint" *ngIf="type === 'ppt'"></i>
    <span class="title" [innerHTML]="title"></span> - Click here to download file
  </a>
</div>
<div *ngIf="type === 'error'" class="error">
  <i class="fas fa-exclamation-triangle"></i>Failed to load attached media.
</div>

