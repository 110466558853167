<div class="main">
  <div class="homepage-grid">
    <div class="col-left">
      <app-recent-updates (fireRefreshFilters)="refreshFilters()"></app-recent-updates>
    </div>
    <div class="col-right">
      <app-favorites></app-favorites>
      <app-audio></app-audio>
    </div>
  </div>
</div>
<app-customize-modal></app-customize-modal>
