import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StringService} from '../../service/string/string.service';
import {DrupalService} from '../../service/drupal/drupal.service';
import {FILTERS} from '../../service/filter/filter-presets';
import {FilterService} from '../../service/filter/filter.service';
import {Filter} from '../../service/filter/filter.interface';
import {FeedComponent} from '../feed.component';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-related',
    templateUrl: './related.component.html',
})
export class RelatedComponent extends FeedComponent implements OnInit {

    constructor(
        private drupalService: DrupalService,
        private router: Router,
        private filterService: FilterService
    ) {
        super();
        this.title = "Recent Updates";
        this.init(window['NUM_RELATED_STANDINS'], window['NUM_RELATED_DISPLAY']);
    }

    /**
     * Get feed pages and information from passed meta info
     */
    ngOnInit() {
        this.loading = true;
        this.getRelatedContent().then(() => this.loading = false)
    }

    /**
     * Get feed content depending on type of feed
     */
    async getRelatedContent() {
        this.feedItems = [];
        let titlePieces = this.router.url.split('/');
        let title = titlePieces[titlePieces.length - 1];
        title = StringService.getStringFromURL(title);
        let relatedFilter: Filter;
        // Audio
        if (title.toLowerCase() === 'podcast') {
            relatedFilter = FILTERS.AUDIO_FILTER;
        } else {
            relatedFilter = this.makeMyFilter(title);
        }
        this.getFeedContent(relatedFilter, this.filterService);
    }

    /**
     * Helper method to generate the filter for this related content feed
     * @param title
     */
    makeMyFilter(title) {
        let category = {};
        category[title] = true;
        return {
            filters: [{
                title: 'category',
                items: [{name: title, checked: true}]
            }]
        };
    }

    getURLString(item) {
        return StringService.getURLString(item);
    }

    stripContainingHTML(string) {
        return StringService.stripContainingHTML(string);
    }

    gaContentEvent(item) {
        return {
            event_name: 'Content',
            nid: item.nid,
            source: this.router.url,
            root_screen: item.field_category,
            title: item.title,
            media_title: item.field_media?.split('^')[0],
            content_type: item.field_type,
            advanced_content_type: item.field_advanced_search,
            organizations: item.field_companies ? item.field_companies : item.field_organization,
            locations: item.field_organization_tids,
            searching: false
        };
    }
}
