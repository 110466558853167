<div class="main">
  <div class="login-holder">
    <div class="card">
      <h3>Sign In</h3>
      <div class="login-form" (keydown)="handleKeyboardEvent($event)">
        <div class="login-field-container">
          <i class="fas fa-user-circle"></i>
          <input #username class="login-field" id="username" type="text" placeholder="Username">
        </div>
        <div class="login-field-container">
          <i class="fas fa-key"></i>
          <input #password class="login-field" id="password" type="password" placeholder="Password">
        </div>
        <div style="text-align: right">
          <div class="loader-container">
            <div *ngIf="isAuthenticating()" class="loader"></div>
            <div #invalidLogin class="invalid-login">Invalid Credentials</div>
          </div>
          <button class="btn-dark btn" (click)="authenticate()" [gaEvent]="{category: 'Login', action: 'click', label: 'Login'}">Login</button>
        </div>
      </div>
    </div>
  </div>
</div>
