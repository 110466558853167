import { Component, Input } from '@angular/core';

@Component({
  template: ''
})
export class FeedComponent {

  @Input() feedItems: Array<any> = [];

  public title: string;
  public NUM_STANDINS;
  public NUM_DISPLAY;
  public loading: boolean;
  public filter: string;
  public standinObjects: Array<any>;

  constructor() {
    this.filter = '';
  }

  init(numStandins, numDisplay) {
    this.NUM_STANDINS = numStandins;
    this.NUM_DISPLAY = numDisplay;
    this.standinObjects = new Array(this.NUM_STANDINS);
  }

  /**
   * Get user-profile-specific filters to add to the filter query
   * @param filterService
   */
  getUserFilters(filterService) {
    return filterService.getFilters(['organization', 'business Area', 'language', 'locale']);
  }

  /**
   * Get feed content depending on type of feed
   */
  async getFeedContent(specificFilter, filterService) {
    let baseFilters = await this.getUserFilters(filterService);
    specificFilter.filters = specificFilter.filters.concat(baseFilters.filters);
    let allFeedItems = await filterService.getWithFilter('node', specificFilter);
    this.feedItems = allFeedItems.slice(0, this.NUM_DISPLAY);
  }

}
