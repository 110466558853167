<div class="main-with-sidebar">
  <div class="article-grid">
    <div class="col-left">
    </div>
    <div class="col-middle-three">
      <div class="article-text">
        <h1>Page Not Found</h1>
        <div class="body-copy">Something went wrong. Click <a routerLink="/home" [gaEvent]="{category: '404 Page', action: 'click', label: 'Home'}">here</a> to return home.</div>
      </div>
  </div>
</div>