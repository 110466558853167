import {Injectable} from '@angular/core';
import {DrupalService} from '../drupal/drupal.service';
import {Filter} from '../filter/filter.interface';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';
import {Profile} from './profile';
import {BusinessArea} from './business-area';
import {AuthService} from '../auth/auth.service';
import {GoogleAnalyticsService} from "../google-analytics/google-analytics.service";

@Injectable({
    providedIn: 'root'
})
export class PersonalizationService {

    private newProf = new Subject<string>();
    public newProf$ = this.newProf.asObservable();
    public profile: any;

    private updating = false;
    private updatingAsync;
    private profileFilters;
    private allProfiles;

    constructor(
        private drupalService: DrupalService,
        private authService: AuthService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.authService.completeUser$.subscribe((complete) => {
            if (complete) {
                this.allProfiles = this.getProfileStart();
            }
        });
    }

    /**
     * Returns array of ids of user's favorites
     * @returns {Promise<Array<any>>}
     */
    getProfile() {
        if (this.updating) {
            return this.updatingAsync.then(() => this.getProfileHelper());
        } else {
            return this.getProfileHelper();
        }
    }

    /**
     * Helper method to get authenticated user's profile settings
     */
    private async getProfileHelper() {
        const user_info = await this.drupalService.getUserInfo();
        const profile = new Profile();
        const businessAreas = user_info[0]['field_wws_business_areas'];
        const personalization = user_info[0]['field_wws_personalization'];
        if (!personalization || !businessAreas) {
            return undefined;
        }
        profile['personalization'] = JSON.parse(personalization.replace(/&quot;/g, "\""));
        profile['business Area'] = JSON.parse(businessAreas.replace(/&quot;/g, "\""));

        return profile;
    }

    /**
     * Get initial profile settings
     */
    async getProfileStart() {
        let filter: Filter = {filters: new Array(4)};
        this.profile = await this.getProfile();
        return Promise.all([
            this.buildFilter(filter, this.drupalService.getTaxonomyTerms('locale'), 'locale', 0),
            this.buildFilter(filter, this.drupalService.getTaxonomyTerms('language'), 'language', 1),
            this.buildFilter(filter, this.drupalService.getTaxonomyTerms('companies'), 'organization', 2),
            this.buildBusinessAreaFilter(filter, this.drupalService.getTaxonomyTerms('dealership-area'), 'business Area', 3)
        ]).then(() => {
            if (!this.profile) {
                filter = this.setStartingProfile(filter);
            }
            const properties = this.googleAnalyticsService.formatUserFilterProperties(filter.filters);
            this.googleAnalyticsService.setUserProperties(properties);
            return filter;
        });
    }

    /**
     * Setup the starting profile
     * @param filter
     */
    setStartingProfile(filter) {
        for (let filt of filter.filters) {
            for (let item of filt.items) {
                if (filt.title === 'organization') {
                    item.checked = false;
                } else if (filt.title === 'language') {
                    item.checked = (item.tid === window['LANGUAGEID'][0]);
                } else if (filt.title === 'locale' && item.tid !== window['LOCALEID']) {
                    item.checked = false;
                } else if (filt.title === 'business Area') {
                    item.checked = false;
                }
            }
        }
        return filter;
    }

    // Helper function to build default Personalization filters
    private async buildFilter(filter, itemsPromise, categoryName, index) {
        let items = await itemsPromise;
        let filterHolder = {};
        for (let category of items) {
            filterHolder[category.name] = true;
            category['checked'] = !(this.profile && this.profile.personalization && !this.profile.personalization[categoryName].includes(category.tid));
        }
        filter.filters[index] = {
            title: categoryName,
            items: items,
            visibleList: filterHolder
        };
    }

    // Helper function to build default Business Area filters
    private async buildBusinessAreaFilter(filter, itemsPromise, categoryName, index) {
        let items = await itemsPromise;
        let filterHolder = {};
        for (let category of items) {
            filterHolder[category.name] = true;
            if (this.profile && this.profile[categoryName] !== undefined && this.profile[categoryName][category.field_organizations]) {
                if (this.profile[categoryName][category.field_organizations]['default'] === 'On') {
                    category['checked'] = !this.profile[categoryName][category.field_organizations]['custom'].includes(category.tid);
                } else {
                    category['checked'] = this.profile[categoryName][category.field_organizations]['custom'].includes(category.tid);
                }
            } else {
                category['checked'] = false;
            }
        }
        filter.filters[index] = {
            title: categoryName,
            items: items,
            visibleList: filterHolder
        };
    }

    /**
     * Get filters for a profile
     */
    async getProfileFilters() {
        if (!this.allProfiles) {
            let types = ['locale', 'language', 'organization', 'business Area'];
            let filter = await this.allProfiles;
            let newFilters = {filters: []};
            for (let type of types) {
                newFilters.filters.push(this.findFilterByName(type, filter));
            }
            // Deep clone the object, so we don't screw up other filters
            return JSON.parse(JSON.stringify(newFilters));
        }
        this.profileFilters = this.allProfiles;
        return this.profileFilters;
    }

    /**
     * Helper method to find filter by name
     * @param name
     * @param allFilters
     */
    findFilterByName(name, allFilters) {
        for (let filter of allFilters.filters) {
            if (filter.title === name.toLowerCase()) {
                return filter;
            }
        }
        return {};
    }

    /**
     * Patches user's profile and business areas based on the selected filters
     * @param saveProfile
     * @returns {any}
     */
    saveProfile(saveProfile: Filter) {
        let profile = {};
        let businessAreas = {};

        // Build the data for the "Personalization" and "Business Area" Drupal profile fields
        for (let filter of saveProfile.filters) {
            // "Personalization" field data
            let arr = [];
            if (filter.title !== 'business Area') {
                for (let item of filter.items) {
                    if (item.checked) {
                        arr.push(item.tid);
                    }
                    profile[filter.title] = arr;
                }
            }

            // "Business Area" field data
            if (filter.title === 'business Area') {
                // Add all organizations to the Business Area field
                let allOrgs = saveProfile.filters.find((filter => filter.title === 'organization'));
                for (let orgItem of allOrgs.items) {
                    let newOrgAreas = new BusinessArea();
                    // Default set to "Off" means BAs are UNSELECTED by default, while "On" means they are SELECTED by default
                    newOrgAreas.default = orgItem['field_business_area_type'];
                    newOrgAreas.custom = [];
                    businessAreas[orgItem.tid] = newOrgAreas;
                }

                // Check which business areas are selected
                for (let area of filter.items) {
                    // Loop through the array of organization term IDs provided by the business area
                    for (let orgTid of area.organizations) {
                        // Add SELECTED Business Area term ID numbers if "default" attribute is "Off" and would not be a duplicate
                        if (businessAreas[orgTid]['default'] === 'Off' && area.checked && !(businessAreas[orgTid]['custom'].includes(area.tid))) {
                            businessAreas[orgTid]['custom'].push(area.tid);
                        }

                        // Add UNSELECTED Business Area term ID numbers if "default" attribute is "On" and would not be a duplicate
                        if (businessAreas[orgTid]['default'] === 'On' && !(area.checked) && !(businessAreas[orgTid]['custom'].includes(area.tid))) {
                            businessAreas[orgTid]['custom'].push(area.tid);
                        }
                    }
                }
            }
        }

        // Patch the user's Drupal profile with the new "Personalization" and "Business Area" values
        this.updatingAsync = this.drupalService.getUserInfo().then(async user_info => {
            const profileSuccess = await this.drupalService.patchProfile(profile, businessAreas, user_info[0]['uid']);
            this.allProfiles = saveProfile;
            this.profile = profileSuccess;
            this.newProf.next(profileSuccess);
            this.updating = false;
            const properties = this.googleAnalyticsService.formatUserFilterProperties(saveProfile.filters);
            this.googleAnalyticsService.setUserProperties(properties);
        });
        return this.updatingAsync;
    }

}
