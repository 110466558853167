import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, RoutesRecognized} from '@angular/router';
import {DrupalService} from '../drupal/drupal.service';
import {GoogleAnalyticsService} from '../google-analytics/google-analytics.service';
import {BehaviorSubject} from "rxjs";
import {filter, pairwise} from 'rxjs/operators';
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class RouteResolverService {

  public prevRoutePath = new BehaviorSubject<string>('');

  constructor(
    private drupalService: DrupalService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private location: Location
  ) {
    this.prevRoutePath.next(this.location.path());
    this.router.events.pipe(filter(e => e instanceof RoutesRecognized), pairwise())
      .subscribe((event: any[]) => this.prevRoutePath.next(event[0].urlAfterRedirects));
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const location = window["ORIGIN_URL"].substring(0, window["ORIGIN_URL"].length - 1) + state.url;
    const path = `/#${state.url}`;
    const titleEl = document.documentElement.querySelector('#title');
    //todo we shouldn't need a separate get here
    if (route.params.id) {
      this.drupalService.get(route.params.id).then(response => {
        const title = response[0].title.trim();
        titleEl.innerHTML = title;
        this.googleAnalyticsService.sendPageViewEvent(title, path, location);
      });
    } else {
      let title = route.url[0].path.trim();
      switch (title) {
        case 'home':
          title = 'What We Sell';
          break;
        case 'oem':
          title = 'OEM';
          break;
        case 'oem-certifications-grid':
          title = 'OEM Certifications Grid';
          break;
        default:
          title = title.charAt(0).toUpperCase() + title.substring(1);
          break;
      }
      titleEl.innerHTML = title;
      this.googleAnalyticsService.sendPageViewEvent(title, path, location);
    }
    this.googleAnalyticsService.setDataLayer({'source': state.url});
    return true;
  }

}
