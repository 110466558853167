import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {ContentComponent} from './content/content.component';
import {LoginComponent} from './login/login.component';
import {GuardService} from '../service/guard/guard.service';
import {ListComponent} from './list/list.component';
import {SearchComponent} from './search/search.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {RouteResolverService} from '../service/route-resolver/route-resolver.service';
import {ProfileModalComponent} from './profile-modal/profile-modal.component';
import {HomeRedirectComponent} from "./home-redirect/home-redirect.component";
import {OemCertGridComponent} from "./oem-cert-grid/oem-cert-grid.component";
import {OemGridGuardService} from "../service/oem-grid-guard/oem-grid-guard.service";

const routes: Routes = [
  {
    path: 'home',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: HomeComponent
  },
  {
    path: 'product/:id',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ContentComponent
  },
  {
    path: 'product/:id/:parent',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ContentComponent
  },
  {
    path: 'oem/:id',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ContentComponent
  },
  {
    path: 'competitive/:id',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ContentComponent
  },
  {
    path: 'news/:id',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ContentComponent
  },
  {
    path: 'sales/:id',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ContentComponent
  },
  {
    path: 'industry/:id',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ContentComponent
  },
  {
    path: 'news',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ListComponent
  },
  {
    path: 'product',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ListComponent
  },
  {
    path: 'not_found',
    resolve: [RouteResolverService],
    component: NotFoundComponent
  },
  {
    path: 'oem',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ListComponent
  },
  {
    path: 'oem-certifications-grid',
    canActivate: [OemGridGuardService],
    canDeactivate: [OemGridGuardService],
    resolve: [RouteResolverService],
    component: OemCertGridComponent
  },
  {
    path: 'competitive',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ListComponent
  },
  {
    path: 'sales',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ListComponent
  },
  {
    path: 'industry',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ListComponent
  },
  {
    path: 'podcast',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ListComponent
  },
  {
    path: 'search/:query',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: SearchComponent
  },
  {
    path: 'search',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: SearchComponent
  },
  {
    path: 'login',
    resolve: [RouteResolverService],
    component: LoginComponent
  },
  {
    path: 'setProfile',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    resolve: [RouteResolverService],
    component: ProfileModalComponent
  },
  {
    path: '',
    canActivate: [GuardService],
    canDeactivate: [GuardService],
    pathMatch: 'full',
    component: HomeRedirectComponent
  },
  {path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
