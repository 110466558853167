import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {GoogleAnalyticsService} from "../../service/google-analytics/google-analytics.service";

@Component({
  selector: 'app-home-redirect',
  templateUrl: './home-redirect.component.html',
  styleUrls: ['./home-redirect.component.scss']
})

/**
 * Component used to preserve utm query parameters before redirection
 */
export class HomeRedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    const params = this.route.snapshot.queryParams;
    const utmParams = this.googleAnalyticsService.extractUtmParams(params);
    const redirectUrl = this.router.createUrlTree(['/home'],  { queryParams: utmParams} ).toString();
    this.router.navigateByUrl(redirectUrl);
  }

}
