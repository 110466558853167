import {Component, HostListener, ViewChild, OnInit, ElementRef} from '@angular/core';
import {DrupalService} from '../../service/drupal/drupal.service';
import {StringService} from '../../service/string/string.service';
import {Router} from '@angular/router';
import {Filter} from '../../service/filter/filter.interface';
import {FilterService} from '../../service/filter/filter.service';
import {GoogleAnalyticsService} from "../../service/google-analytics/google-analytics.service";
import {FILTERS} from '../../service/filter/filter-presets';
import {AuthService} from "../../service/auth/auth.service";
import {PersonalizationService} from "../../service/personalization/personalization.service";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @ViewChild('filterInput', {static: true}) filterListInput: ElementRef

  private newsDone = true;
  title: string;
  feedType = 'default';
  loading = false;
  list = [];
  filteredList = [];
  PAGE_SIZE = 20;
  org = '';
  filtersList: Filter = {filters: []};
  numStandins = new Array(10);
  timer = null;
  oemGridEnabled = false;

  constructor(
    private drupalService: DrupalService,
    private filterService: FilterService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private personalizationService: PersonalizationService
  ) {
  }

  ngOnInit() {
    this.title = document.title;
    this.personalizationService.getProfile().then(profile => {
      let locale = profile['personalization']['locale'][0];
      this.oemGridEnabled = ((locale === window['US_LOCALE_TID']) && window['OEM_GRID_ENABLED_US'])
        || ((locale === window['CA_LOCALE_TID']) && window['OEM_GRID_ENABLED_CA']);
    });
    // Get filters
    this.filterService.getFilters(['organization', 'business Area', 'language', 'locale']).then(filter => {
      this.setHidden(filter);
      this.filtersList = filter;
      this.update();
    });
  }

  update() {
    this.feedType = this.title.toLowerCase();

    switch (this.feedType) {
      case 'news':
        this.getNewsContent();
        break;
      case 'oem':
        this.getContent();
        break;
      case 'podcast':
        this.getAudioContent();
        break;
      default:
        this.getContent();
        this.feedType = 'default';
    }
  }

  /**
   * Listens for scroll events to load more results when the bottom of the page is reached
   */
  @HostListener('document:scroll') handleScroll() {
    if (!this.loading && this.feedType === 'news' && window.scrollY + window.innerHeight === document.documentElement.scrollHeight) {
      this.getNewsContent();
    }
  }

  /**
   * Get audio
   */
  getAudioContent() {
    this.filterService.getWithFilter('node', FILTERS.AUDIO_FILTER).then(response => {
      this.list = response;
      this.filteredList = this.list;
      this.loading = false;
      this.updateList();
    });
  }

  /**
   * Get list of content
   */
  getContent() {
    this.loading = true;
    this.drupalService.getTerms(this.title.toLowerCase()).then(response => {
      this.list = response;
      this.filteredList = this.list;
      this.loading = false;
      this.updateList();
    }).catch(() => {
      this.loading = false;
    });
  }

  /**
   * Get content for news
   */
  getNewsContent() {
    let hitBottom = Math.round(this.list.length / this.PAGE_SIZE);
    if ((this.list.length === hitBottom * this.PAGE_SIZE) && this.newsDone) {
      this.loading = true;
      const filter: Filter = JSON.parse(JSON.stringify(this.filtersList));
      filter.filters.push({title: 'category', items: [{name: 'News', checked: true}]});
      filter.filters.push({title: 'type', items: [{name: 'Text', checked: true}]});
      filter.filters.push({title: 'offset', items: [{name: hitBottom * this.PAGE_SIZE, checked: true}]});
      this.filterService.getWithFilter('node', filter, this.org).then(response => {
        this.list = this.list.concat(response);
        this.filteredList = this.list;
        this.loading = false;
      });
    }
  }

  /**
   * Updates hidden status on filter change
   */
  updateList() {
    if (this.feedType === 'news') {
      this.list = [];
      this.filteredList = this.list;
      this.newsDone = true;
      this.getNewsContent();
    } else {
      for (let item of this.list) {
        item['hidden'] = !this.filterService.filterOut(item, this.filtersList);
      }
      this.updatePinToTopList();
    }
  }

  /**
   * Update list to push pinned items to top of list
   */
  updatePinToTopList() {
    let pinToTopList = this.list.filter(item => item.field_sticky === 'True');
    let unpinnedList = this.list.filter(item => item.field_sticky !== 'True');
    this.list = pinToTopList.concat(unpinnedList);
    this.filteredList = this.list;
  }

  /**
   * Filters the list from the search text
   */
  searchChange() {
    const newVal = this.filterListInput.nativeElement.value.trim().toUpperCase();
    clearTimeout(this.timer);
    if (newVal === '') {
      this.filteredList = this.list;
      return;
    }

    this.timer = setTimeout(() => {
      const regex = new RegExp(`\\b${newVal.toUpperCase()}.*`)
      this.filteredList = this.list.filter(item => regex.test(item.title.toUpperCase()));
      this.googleAnalyticsService.sendAction('FilterTerm', {
        event_name: 'FilterTerm',
        filter_term: newVal,
        source: this.router.url
      });
    }, 500);
  }

  // Select organization type (title or date) and update accordingly
  selectOrg(event) {
    this.org = event.target.value;
    this.updateList();
  }

  getURLString(string) {
    return StringService.getURLString(string);
  }

  stripContainingHTML(string) {
    return StringService.stripContainingHTML(string);
  }

  setHidden(filter) {
    let language = filter.filters.find((f => f.title === 'language'));
    let locale = filter.filters.find((f => f.title === 'locale'));
    if (language && locale) {
      language.hidden = true;
    }
  }

  gaContentEvent(item) {
    return {
      event_name: 'Content',
      nid: item.nid,
      source: this.router.url,
      root_screen: item.field_category,
      title: item.title,
      media_title: item.field_media?.split('^')[0],
      content_type: item.field_type,
      advanced_content_type: item.field_advanced_search,
      organizations: item.field_organization_tids,
      locations: item.field_locales ? item.field_locales : item.field_locale,
      searching: false
    };
  }
}
