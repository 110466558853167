import {Component} from '@angular/core';
import {FeedComponent} from '../feed.component';
import {StringService} from '../../service/string/string.service';
import {FavoritesService} from '../../service/favorites/favorites.service';
import {GoogleAnalyticsService} from "../../service/google-analytics/google-analytics.service";
import {Router} from "@angular/router";
import {DrupalService} from '../../service/drupal/drupal.service';

@Component({
    selector: 'app-favorites',
    templateUrl: './favorites.component.html'
})
export class FavoritesComponent extends FeedComponent {

    public editFavorites = false;
    public favoriteItems = [];
    public recommendedItems = [];
    public recommendations;

    constructor(
        private favoritesService: FavoritesService,
        private drupalService: DrupalService,
        private router: Router,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        Promise.all([
            this.getFavorites(),
            this.getRecommended()
        ]).then(() => {
            this.loading = false;
        })
    }

    /**
     * Gets saved recommended and removes those that no longer exist
     * @returns {Promise<void>}
     */
    async getRecommended() {
        this.recommendations = await this.favoritesService.getRecommended();
        this.recommendedItems = await this.removeExtinctPages(this.recommendations.recommendations);
    }

    /**
     * Gets saved favorites and removes those that no longer exist
     * @returns {Promise<void>}
     */
    async getFavorites() {
        let allFaves = await this.favoritesService.getFavorites();
        this.favoriteItems = await this.removeExtinctPages(allFaves);
    }

    /**
     * Helper to return all non-existent favorites
     * @returns {Promise<any>}
     */
    removeExtinctPages(pages) {
        let existingPromise = [];
        let renderItems = [];
        for (let fave of pages) {
            existingPromise.push(
                this.drupalService.get(fave).then(page => {
                    if (page[0]) {
                        renderItems.push(page[0]);
                    }
                }));
        }
        return Promise.all(existingPromise).then(() => {
            return renderItems;
        });
    }

    getURLString(item) {
        return StringService.getURLString(item);
    }

    /**
     * Trim long text
     * @param string
     * @returns {any}
     */
    trim(string) {
        if (string.length > 120) {
            return string.substring(0, 120) + '...';
        } else {
            return string;
        }
    }

    toggleEditFaves() {
        this.editFavorites = !this.editFavorites;
    }

    /**
     * Deletes selected favorites
     */
    deleteSelected() {
        let newArr = [];
        for (let i = 0; i < this.favoriteItems.length; i++) {
            if (!this.favoriteItems[i]['checked']) {
                newArr.push(this.favoriteItems[i]);
            } else {
                this.googleAnalyticsService.sendAction('RemoveFavorite', {
                    event_name: 'RemoveFavorite',
                    title: this.favoriteItems[i].title,
                    nid: this.favoriteItems[i].nid
                });
            }
        }
        this.favoriteItems = newArr;
        let newFaves = [];
        for (let page of newArr) {
            newFaves.push(page.nid);
        }
        this.feedItems = this.favoriteItems;
        this.favoritesService.setFavorites(newFaves);
        this.toggleEditFaves();
    }

    gaContentEvent(item) {
        return {
            event_name: 'Content',
            nid: item.nid,
            source: this.router.url,
            root_screen: item.field_category,
            title: item.title,
            media_title: item.field_media?.split('^')[0],
            content_type: item.field_type,
            advanced_content_type: item.field_advanced_search,
            organizations: item.field_organization_tids,
            locations: item.field_locales ? item.field_locales : item.field_locale,
            searching: false
        };
    }
}
