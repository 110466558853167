<div class="news-item">
  <span class="date" [innerHTML]="item.changed"></span>
  <div style="display:flex">
    <div style="display:inline-block">
      <h4 [innerHTML]="stripContainingHTML(item.title)"></h4>
      <p class="body-copy" [innerHTML]="stripContainingHTML(item.field_summary)"></p>
    </div>
  </div>
  <hr>
  <div class="news-footer">
    <div class="news-type {{item.field_type}}">
      <i class="fas fa-file-alt" *ngIf="item.field_type === 'Text'"></i>
      <i class="fas fa-file-word" *ngIf="item.field_type === 'Word Document'"></i>
      <i class="fas fa-file-pdf" *ngIf="item.field_type === 'PDF'"></i>
      <i class="fas fa-file-excel" *ngIf="item.field_type === 'Excel Sheet'"></i>
      <i class="fas fa-file-powerpoint" *ngIf="item.field_type === 'PowerPoint'"></i>
      <i class="fas fa-play" *ngIf="item.field_type === 'Audio'"></i>
      <i class="fas fa-play-circle" *ngIf="item.field_type === 'Video'"></i>
    </div>
    <div class="news-continue">
      <a routerLink="/{{getURLString(item.field_category)}}/{{item.nid}}" *ngIf="item.field_type !== 'Audio' && item.field_type !== 'Video'" [gaEvent]="gaContentEvent(item)">Continue Reading</a>
      <a routerLink="/{{getURLString(item.field_category)}}/{{item.nid}}" *ngIf="item.field_type === 'Audio'" [gaEvent]="gaContentEvent(item)">Listen Now</a>
      <a routerLink="/{{getURLString(item.field_category)}}/{{item.nid}}" *ngIf="item.field_type === 'Video'" [gaEvent]="gaContentEvent(item)">Watch Now</a>
      <i class="continue-icon continue"></i>
    </div>
  </div>
</div>