import {Directive, HostListener, Input} from '@angular/core';
import { GoogleAnalyticsService } from '../../service/google-analytics/google-analytics.service';

@Directive({
  selector: '[gaEvent]'
})
export class GaEventDirective {

    @Input('gaEvent') option:any;

    @HostListener('click', ['$event']) onClick($event){
      this.googleAnalyticsService.sendAction(this.option.event_name ?? 'click', this.option);
    }

    constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

}
