<div class="main">
  <div class="list-grid">
    <div class="col-left">
      <app-breadcrumbs [title]="'Search'"></app-breadcrumbs>
      <div class="article-text">
        <h1>Search Results</h1>
        <div class="body-copy">
          <div style="position:relative">
            <input class="filter-input" placeholder="Search What We Sell" id="search-input"
                   (keydown)="handleKeyboardEvent($event)" [(ngModel)]="query">
            <button class="wws-search-btn" (click)="navigateForSearch()"></button>
          </div>
          <div class="search-show">
            <div class="search-filter">
              <span style="margin-right:2px;">Search by:</span>
              <select id="search-filter-dropdown" (change)="search()" [(ngModel)]="searchBy">
                <option value="everything">Everything</option>
                <option value="title">Title</option>
                <option value="body">Body</option>
              </select>
            </div>
            <app-filter (update)="search()" [(filtersList)]="filtersList"></app-filter>
          </div>
          <div class="search-results">
            <div *ngFor="let item of searchResults" [ngClass]="{'hidden' : item['hidden']}">
              <hr>
              <div class="result-item">
                <a class="header-5 inline-link" routerLink="/{{getURLString(item.field_category)}}{{item.nid}}" (click)="routeToContent(item)" [innerHTML]="item.title"></a>
                <p [innerHTML]="item.field_summary"></p>
              </div>
            </div>
            <div *ngIf="searchResults.length === 0 && !loading" class="no-results">
              No results.
            </div>
          </div>
          <div class="loader-fullpage-container" *ngIf="loading">
            <div class="loader-large"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
