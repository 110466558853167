import {Component, Input, OnInit} from '@angular/core';
@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() id: string;
  @Input() iconClass: string;
  @Input() title: string;
  @Input() options;

  constructor() { }

  ngOnInit() {
  }
}
