import { Component, ElementRef, ViewChild, Renderer2, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { AuthService } from '../../service/auth/auth.service';
import { DrupalService } from '../../service/drupal/drupal.service';
import { FilterService } from '../../service/filter/filter.service';
import { StringService } from '../../service/string/string.service';
import {GoogleAnalyticsService} from "../../service/google-analytics/google-analytics.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild('mobileButtonMenu') mobileButtonMenu: ElementRef;
  @ViewChild('buttonMenu') buttonMenu: ElementRef;

  public query: string;
  public username;
  public userDropdownOptions = [
    {
      name: 'Edit profile',
      icon: 'cog',
      resolve: () => {
        this.editProfile();
      }
    },
    {
      name: 'Sign out',
      icon: 'sign-out-alt',
      resolve: () => {
        this.logout();
      }
    }
  ];
  public toolDropdownOptions = [
    {
      name: 'SalesPRO',
      icon: 'external-link-square-alt',
      resolve: () => {
        let url = this.getSalesProURL();
        this.googleAnalyticsService.sendAction('LinkClick', {
          event_name: 'LinkClick',
          link_url: `${url}`,
          link_text: 'SalesPRO',
          nav_location: 'Header',
          title: 'Tools',
          source: this.router.url
        });
        window.location.href = url;
      }
    },
    {
      name: 'TBRey',
      icon: 'external-link-square-alt',
      resolve: () => {
        let url = this.getTBReyURL();
        this.googleAnalyticsService.sendAction('LinkClick', {
          event_name: 'LinkClick',
          link_url: `${url}`,
          link_text: 'TBRey',
          nav_location: 'Header',
          title: 'Tools',
          source: this.router.url
        });
        window.location.href = url;
      }
    }
  ];
  public categories = [];

  private userOrganizationFilterSubscription: Subscription;
  private routeSub: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private filterService: FilterService,
    private drupalService: DrupalService,
    private renderer: Renderer2,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.getUser();
    this.routeSub = this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.getUser();
      }
    });

    this.authService.completeUser$.subscribe(complete => {
      if (complete) {
        // Get current recent updates
        this.filterService.getOrganizationsFilter().then(newFilters => {
          this.getCategories(newFilters);
        });
        // Subscribe to observable in order to update user's categories when selected organizations change
        this.userOrganizationFilterSubscription = this.filterService.userOrganizationStream.subscribe(newFilters => {
          this.getCategories(newFilters);
        });
      }
    });
  }

  /**
   * Gets username for logged in user
   */
  getUser() {
    this.authService.getUser().then(response => this.username = response);
  }

  filterOrganizations(filter) {
    let organizations = filter.filters.find(({title}) => title === 'organization');
    let visibles = organizations.visibleList;
    let orgFilter = '';

    for (let key in visibles) {
      if (visibles[key]) {
        orgFilter += key + ',';
      }
    }
    return orgFilter;
  }

  getCategories(filter) {
    let userFilter = this.filterOrganizations(filter);

    this.drupalService.getCategoryByOrganization(userFilter).then((response) => {
      this.categories = response;
      this.insertCategoryIcons();
    });
  }

  insertCategoryIcons() {
    for (let category of this.categories) {
      category.icon = StringService.getStringIcon(category.name);
    }
  }

  // Go to given url
  goTo(string) {
    let url = StringService.getURLString(string);
    this.router.navigate([url]);
  }

  // When Enter is used to submit search
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.search();
    }
  }

  // Helper DOM method to open hamburger menu
  toggleMobileButtonMenu() {
    if (this.buttonMenu.nativeElement.classList.contains('show')) {
      this.renderer.removeClass(this.buttonMenu.nativeElement, 'show');
    } else {
      this.renderer.addClass(this.buttonMenu.nativeElement, 'show');
      this.googleAnalyticsService.sendAction('TabBarExpansion', {source: this.router.url});
    }
  }

  // Navigates to search page with query
  search() {
    this.router.navigateByUrl(this.query ? `/search/${this.query}` : '/search');
  }

  /**
   * Logs out and clears cookies
   */
  logout() {
    this.authService.logout();
  }

  editProfile() {
    this.googleAnalyticsService.sendAction('UserPersonalization',
        {
          event_name: 'UserPersonalization',
          source: this.router.url
        });
    this.authService.redirectUrl = this.router.url;
    this.router.navigateByUrl('/setProfile');
  }

  getSalesProURL() {
    return window['SALESPRO_URL'];
  }

  getTBReyURL() {
    return window['TBREY_URL'];
  }

}
