import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DrupalService} from '../../service/drupal/drupal.service';
import {AuthService} from '../../service/auth/auth.service';
import {Router} from '@angular/router';
import {GoogleAnalyticsService} from "../../service/google-analytics/google-analytics.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../home/home.component.scss'],
})
export class LoginComponent {
    @ViewChild('username') username: ElementRef;
    @ViewChild('password') password: ElementRef;
    @ViewChild('invalidLogin') errorMessage: ElementRef;

  constructor(
      private authService: AuthService,
      private router: Router,
      private renderer: Renderer2,
      private googleAnalyticsService: GoogleAnalyticsService
  ) { }

    /**
     * Authenticates users credentials
     */
  authenticate(){
      this.renderer.setStyle(this.errorMessage.nativeElement, 'display', 'none');
      this.authService.login(
          this.username.nativeElement.value.trim(),
          this.password.nativeElement.value,
          () => this.router.navigate([this.authService.redirectUrl]),
          () => this.renderer.setStyle(this.errorMessage.nativeElement, 'display', 'block')
      );
  }

  // Helper to return status of auth
  isAuthenticating(){
    return this.authService.authenticating;
  }

  // Handling when enter is pressed
  handleKeyboardEvent(event: KeyboardEvent){
      event.stopPropagation();
      if (event.key === 'Enter') {
        this.googleAnalyticsService.sendAction("login", {category: "Login", label: "login"});
        this.authenticate();
      }
  }
}
