import { Injectable } from '@angular/core';
import {NEWSLETTER_ITEM, NEWSLETTER_TEMPLATE} from './newsletter_template';
import {StringService} from '../string/string.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor() { }

    /**
     * Creates and return a full html email given an array of nodes
     * @param feed
     * @returns {string}
     */
  makeCopyText(feed){
    let text = NEWSLETTER_TEMPLATE;
    let items = this.makeItems(feed);
    return text.replace("%%item%%", items);
  }

    /**
     * Loops through nodes to generate html string
     * @param feed
     * @returns {string}
     */
  makeItems(feed){
    let string = "";
    for (let item of feed){
      let holder = NEWSLETTER_ITEM.replace("%%date%%", item.changed).replace("%%title%%", item.title)
          .replace("%%body%%", this.stripContainingHTML(item.field_summary)).replace("%%nid%%", item.nid)
          .replace('%%cat%%', this.getURLString(item.field_category)).replace('%%origin%%', window['ORIGIN_URL']);
      string += holder;
    }
    return string;
  }

    stripContainingHTML(string){
        return StringService.stripContainingHTML(string);
    }

    getURLString(item){
        return StringService.getURLString(item);
    }
}
