<ng-template #profileModal id="personalize-modal" tabindex="-1" role="dialog"
             aria-labelledby="personalize-modal" data-backdrop="static">
  <div class="fa-times-thin modal-close" (click)="cancel()"
       [gaEvent]="{category: 'Personalize Filter Modal', action: 'click', label: 'Close'}"></div>
  <div class="modal-header source-sans">
    <h3>User Profile</h3>
  </div>
  <div class="modal-body source-sans">
    <div *ngIf="!done"><h5 class="profile-warning">You need to fill out a profile to access What We Sell</h5></div>
    <div class="customize-grid" *ngIf="filter">
      <div class="customize-column" *ngFor="let filterItem of filter.filters">
        <ng-container *ngIf="!filterItem.hidden">
          <h6 class="warning" style="padding: 5px;" *ngIf="filterItem.title === 'business Area' && baCount === 0">
            *You must select at least one Business Area.</h6>
          <h6 class="warning" style="padding: 5px;" *ngIf="filterItem.title === 'organization' && orgCount === 0">
            *You must select at least one Organization.</h6>
          <h4 [innerHTML]="getTitleFromField(filterItem.title)"></h4>
          <ul>
            <ng-container *ngFor="let item of filterItem.items">
              <hr *ngIf="item.tid === rdsid">
              <li *ngIf="item.hidden !== true" (click)="toggleFilter(item, filterItem)"
                  [ngClass]="{'separate': item.tid === rdsid}">
                {{item.name}}
                <div class="star" [ngClass]="{'checked': item.checked}"
                     [gaEvent]="{category: 'Personalize Filter Modal', action: 'click', label: item.name}">
                  <!-- The "far fa-square" class is an empty square used for unchecked Languages and Business Areas -->
                  <span class="far fa-square"
                        *ngIf="!item.checked && (filterItem.title === 'language' || filterItem.title === 'business Area')"></span>
                  <!-- The "far fa-circle" class is an empty circle used for unchecked Organizations and Locales -->
                  <span class="far fa-circle"
                        *ngIf="!item.checked && filterItem.title !== 'language' && filterItem.title !== 'business Area'"></span>
                  <!-- The "fas fa-check-circle check" class is a filled-and-checked circle used for checked Organizations -->
                  <span class="fas fa-check-circle check"
                        *ngIf="item.checked && filterItem.title === 'organization'"></span>
                  <!-- The "fas fa-check-square check" class is a filled-and-checked square used for checked Languages and Business Areas -->
                  <span class="fas fa-check-square check"
                        *ngIf="item.checked && (filterItem.title === 'language' || filterItem.title === 'business Area')"></span>
                  <!-- The "fas fa-dot-circle check" class is a filled-and-dotted circle used for checked Locales -->
                  <span class="fas fa-dot-circle check"
                        *ngIf="item.checked && filterItem.title === 'locale'"></span>
                </div>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </div>
    </div>
    <div class="loader-container" *ngIf="!filter">
      <div class="loader"></div>
    </div>
    <div style="text-align: right">
      <div class="btn btn-special" (click)="cancel()"
           [gaEvent]="{category: 'Personalize Filter Modal', action: 'click', label: 'Cancel'}">Cancel
      </div>
      <div class="btn btn-grey" (click)="apply()"
           [gaEvent]="{category: 'Personalize Filter Modal', action: 'click', label: 'Apply'}">Apply
      </div>
    </div>
  </div>
</ng-template>
