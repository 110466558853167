import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FilterService} from '../../service/filter/filter.service';
import {Router} from '@angular/router';
import {AuthService} from '../../service/auth/auth.service';
import {NewsletterService} from '../../service/newsletter/newsletter.service';
import {FeedComponent} from '../feed.component';

@Component({
    selector: 'app-recent-updates',
    templateUrl: './recent-updates.component.html'
})
export class RecentUpdatesComponent extends FeedComponent implements OnInit {

    @Output() fireRefreshFilters: EventEmitter<any> = new EventEmitter();

    public newsletterLoading;
    public newsletterSuccessFlash = false;

    private changed_in = 'seven_days';
    private recentUpdatesFilterSubscription;
    private recentUpdatesFilters;

    constructor(
        private router: Router,
        private filterService: FilterService,
        private authService: AuthService,
        private newsletterService: NewsletterService,
    ) {
        super();
        this.init(window['NUM_RECENT_UPDATES_STANDINS'], window['NUM_RECENT_UPDATES_DISPLAY']);
        this.title = 'Recent Updates';
    }

    /**
     * Get feed pages and information from passed meta info
     */
    ngOnInit() {
        this.feedItems = [];
        // Get current recent updates
        this.filterService.getRecentUpdatesFilter().then(newFilters => this.reinitRecentUpdates(newFilters));
        // Subscribe to observable in order to update recent updates when the filters change
        this.recentUpdatesFilterSubscription = this.filterService.recentUpdatesStream.subscribe(newFilters => {
            this.reinitRecentUpdates(newFilters);
        })
    }

    reinitRecentUpdates(newFilters) {
        this.feedItems = [];
        this.recentUpdatesFilters = newFilters;
        this.getRecentUpdates();
    }

    /**
     * Get feed content depending on type of feed
     */
    async getRecentUpdates(hitBottom?) {
        this.loading = true;
        const baseFilters = JSON.parse(JSON.stringify(this.recentUpdatesFilters));
        const userFilters = await this.getUserFilters(this.filterService);
        baseFilters.filters = baseFilters.filters.concat(userFilters.filters);
        const newBits = await this.filterService.getRecentUpdatesWithFilter(baseFilters, 'node_' + this.changed_in, this.NUM_DISPLAY * ((hitBottom) ? hitBottom : 0));
        this.feedItems = this.feedItems.concat(newBits);
        this.loading = false;
    }

    // Helper method to return if user has permission to copy newsletter html
    getNewsletterPermission() {
        return this.authService.hasNewsletterPermission;
    }

    // Helper method to load more results for infinite scrolling
    loadMore() {
        const hitBottom = Math.round(this.feedItems.length / this.NUM_DISPLAY);
        if (!this.loading && this.feedItems.length === hitBottom * this.NUM_DISPLAY) {
            this.getRecentUpdates(hitBottom);
        }
    }

    /**
     * Gets html string of elements and copies to clipboard
     */
    async copyHTML() {
        let textarea = document.createElement('textarea');
        this.newsletterLoading = true;
        let newsFeed = await this.filterService.getRecentUpdatesWithFilter(this.recentUpdatesFilters, 'node_email', 0);
        textarea.value = this.newsletterService.makeCopyText(newsFeed);
        document.body.appendChild(textarea);
        textarea.select();
        //todo
        if (document.execCommand('copy')) {
            this.alertCopied();
        }
        this.newsletterLoading = false;
        textarea.remove();
    }

    /**
     * Alerts user that the text was copied to clipboard
     */
    alertCopied() {
        this.newsletterSuccessFlash = true;
        setTimeout(() => this.newsletterSuccessFlash = false, 2500);
    }

    // Helper to change date range
    selectChanged(event) {
        this.changed_in = event.target.value;
        this.feedItems = [];
        this.getRecentUpdates();
    }

    // Helper to open modal
    openCustomizeModal() {
        this.fireRefreshFilters.emit();
    }

    selectFilter(string) {
        this.filter = string;
    }

    gaFilterModalEvent() {
        return {
            event_name: 'FilterModal',
            source: this.router.url
        }
    }
}
