import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {GoogleAnalyticsService} from "../../service/google-analytics/google-analytics.service";
import {RouteResolverService} from "../../service/route-resolver/route-resolver.service";
import {DomSanitizer} from "@angular/platform-browser";
import {VimeoService} from "../../service/vimeo/vimeo.service";

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['../content/content.component.scss'],
})
export class MediaComponent implements OnInit, AfterViewInit {

  @Input() media;
  @Input() page;
  @ViewChild('audioElement') audioElement: ElementRef<HTMLAudioElement>;
  @ViewChild('videoTranscript') videoTranscript: ElementRef;
  @ViewChild('videoFrame') videoFrame: ElementRef;

  type;
  title;
  transcript = '';
  resource_url: any = this.sanitizer.bypassSecurityTrustResourceUrl('');
  progress = 0;
  previousTime = 0;
  seek_tid = null;
  isSeeking = false;
  videoDuration = 0;
  mediaTypeMap = {
    'pdf': 'pdf',
    'vimeo': 'video',
    'mp3': 'audio',
    '.doc': 'word',
    '.ppt': 'ppt',
    '.xl': 'xl'
  }

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private routeResolverService: RouteResolverService,
    private sanitizer: DomSanitizer,
    private vimeoService: VimeoService
  ) {
  }

  ngOnInit() {
    const bits = this.media.split('^~');
    if (bits.length < 2) {
      this.type = 'error';
      return;
    }

    this.title = bits[0];
    const mediaBit = bits[1];
    const urlBit = bits[bits.length - 1];

    Object.keys(this.mediaTypeMap).forEach((key: string) => {
      if (mediaBit.toLowerCase().indexOf(key) !== -1) {
        this.type = this.mediaTypeMap[key];
        return;
      }
    });

    if (this.type === 'error') {
      return;
    }

    switch(this.type) {
      case 'pdf':
        this.resource_url = this.sanitizer.bypassSecurityTrustResourceUrl(window['DRUPAL_URL_FILE'] + mediaBit);
        break;
      case 'video':
        this.displayVideo(mediaBit, urlBit);
        break;
      case 'audio':
        this.displayAudio(mediaBit, urlBit);
        break;
      case 'word':
      case 'ppt':
      case 'xl':
        this.resource_url = this.sanitizer.bypassSecurityTrustResourceUrl(window['DRUPAL_URL_FILE'] + urlBit);
        break;
      default:
        break;
    }
  }

  ngAfterViewInit() {
    if (this.type === 'video') {
      this.vimeoService.initVimeoPlayer();
      this.initVideoEventListeners();
    }
  }

  // Video
  displayVideo(url: string, transcript) {
    const parsedUrl = new DOMParser().parseFromString(url, 'text/html').querySelector('a').innerHTML;
    const regex = /\/[0-9]+/g;
    const vid = regex.exec(parsedUrl)[0];
    this.resource_url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://player.vimeo.com/video${vid}`);
    this.transcript = transcript;
  }

  initVideoEventListeners() {
    const eventName = 'Video';
    this.vimeoService.player.getDuration().then(duration => {
      this.videoDuration = duration;

      this.vimeoService.player.on('play', () => {
        this.vimeoService.player.getCurrentTime().then(currentTime => {
          this.handlePlayEvent(currentTime, this.videoDuration, eventName);
        });
      });

      this.vimeoService.player.on('pause', () => {
        this.vimeoService.player.getCurrentTime().then(currentTime => {
          this.handlePauseEvent(currentTime, this.videoDuration, eventName);
        });
      });

      this.vimeoService.player.on('ended', () => {
        this.vimeoService.player.getCurrentTime().then(currentTime => {
          this.handleEndedEvent(this.videoDuration, eventName);
        });
      });

      this.vimeoService.player.on('timeupdate', () => {
        this.vimeoService.player.getCurrentTime().then(currentTime => {
          this.handleTimeUpdate(currentTime, this.videoDuration, eventName);
        });
      });

      this.vimeoService.player.on('seeked', () => {
        this.vimeoService.player.getCurrentTime().then(currentTime => {
          this.handleSeekEvent(currentTime, eventName);
        })
      });
    })
  }

  // Audio
  displayAudio(url, transcript) {
    this.resource_url = this.sanitizer.bypassSecurityTrustResourceUrl(window['DRUPAL_URL_FILE'] + url);
    this.transcript = transcript;
  }

  audioEvent(event) {
    const eventName = 'Audio';
    const nativeAudioEl = this.audioElement.nativeElement;
    switch (event.type) {
      case 'timeupdate':
        this.handleTimeUpdate(nativeAudioEl.currentTime, nativeAudioEl.duration, eventName);
        break;
      case 'seeked':
        this.handleSeekEvent(nativeAudioEl.currentTime, eventName);
        break;
      case 'play':
        this.handlePlayEvent(nativeAudioEl.currentTime, nativeAudioEl.duration, eventName);
        break;
      case 'pause':
        this.handlePauseEvent(nativeAudioEl.currentTime, nativeAudioEl.duration, eventName);
        break;
      case 'ended':
        this.handleEndedEvent(nativeAudioEl.duration, eventName)
        break;
      default:
        break;
    }
  }

  handleEndedEvent(duration, eventName) {
    this.googleAnalyticsService.sendAction(eventName, {
      nid: this.page.nid,
      title: this.page.title,
      media_title: this.title,
      action: 'Complete',
      source: this.routeResolverService.prevRoutePath.value,
      total_duration: duration
    });
  }

  handlePauseEvent(currentTime, duration, eventName) {
    //10ms delay so pause events fire after other events
    setTimeout(() => {
      if (!this.isSeeking && currentTime < duration) {
        this.googleAnalyticsService.sendAction(eventName, {
          nid: this.page.nid,
          title: this.page.title,
          media_title: this.title,
          action: 'Pause',
        });
      }
    }, 10);
  }

  handlePlayEvent(currentTime, duration, eventName) {
    let properties = {};
    if (currentTime === 0) {
      properties = {
        nid: this.page.nid,
        title: this.page.title,
        media_title: this.title,
        action: 'Start',
        source: this.routeResolverService.prevRoutePath.value,
        total_duration: duration
      }
    } else {
      if (!this.isSeeking) {
        properties = {
          nid: this.page.nid,
          title: this.page.title,
          media_title: this.title,
          action: 'Play',
        };
      }
    }
    this.googleAnalyticsService.sendAction(eventName, properties);
  }

  handleTimeUpdate(currentTime, duration, eventName) {
    //20ms delay to prevent constant time updates from interfering with seek events
    setTimeout(() => {
      let currentProgress = Math.floor((currentTime / duration) * 20) * 5; //round to nearest 5
      if (!this.isSeeking && Math.abs(currentProgress - this.progress) >= 5) {
        this.googleAnalyticsService.sendAction(eventName, {
          nid: this.page.nid,
          title: this.page.title,
          media_title: this.title,
          action: 'Progress',
          progress: currentProgress
        });
        this.progress = currentProgress;
      }
      if (!this.isSeeking) {
        this.previousTime = currentTime;
      }
    }, 20);
  }

  handleSeekEvent(currentTime, eventName) {
    this.isSeeking = true;
    if (this.seek_tid) {
      clearTimeout(this.seek_tid);
    }
    //wait for seek events to stop firing
    this.seek_tid = setTimeout(() => {
      const seekDirection = currentTime < this.previousTime ? 'backward' : 'forward';
      this.googleAnalyticsService.sendAction(eventName, {
        nid: this.page.nid,
        title: this.page.title,
        media_title: this.title,
        action: 'Seek',
        seek_direction: seekDirection
      });
      this.previousTime = currentTime;
      this.seek_tid = null;
      this.isSeeking = false;
    }, 250);
  }
}
