import {Component, OnInit} from '@angular/core';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public year: string;
  public assetUrl: string;

  constructor() { }

  ngOnInit() {
    this.year = new DatePipe('en-US').transform(new Date(), 'YYYY');
    this.assetUrl = window['ASSET_URL'];
  }
}
