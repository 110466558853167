import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {RecentUpdatesComponent} from './recent-updates/recent-updates.component';
import {AppRoutingModule} from '../common/app-routing.module';
import {FilterService} from '../service/filter/filter.service';
import { AudioComponent } from './audio/audio.component';
import { ResourcesComponent } from './resources/resources.component';
import { RelatedComponent } from './related/related.component';
import { NewsItemComponent } from './news-item/news-item.component';
import {GaEventDirective} from '../directive/ga-event/ga-event.directive';
import {FeedComponent} from './feed.component';
import { FavoritesComponent } from './favorites/favorites.component';
import {DrupalService} from '../service/drupal/drupal.service';

@NgModule({
  declarations: [
      RecentUpdatesComponent,
      AudioComponent,
      ResourcesComponent,
      RelatedComponent,
      NewsItemComponent,
      FeedComponent,
      GaEventDirective,
      FavoritesComponent
  ],
  providers: [
      FilterService,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
  ],
  exports: [
      RecentUpdatesComponent,
      AudioComponent,
      ResourcesComponent,
      RelatedComponent,
      FavoritesComponent,
      NewsItemComponent,
      GaEventDirective
  ]
})
export class FeedModule { }
