import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DrupalService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {
    }

    /**
     * All drupal requests pass through this method for additional formatting
     * @param url
     * @returns {Promise<Object>}
     */
    async sendHttpRequest(url): Promise<any> {
        await this.authService.getUser();
        return await this.http.get(url, {withCredentials: true})
            .pipe(tap(val => val, error => console.log(error)))
            .toPromise();
    }

    /**
     * All jquery ajax requests pass through this method for additional formatting
     * @param data
     * @param url
     */
    async sendPatchRequest(data: Object, url: string) {
        const token = await this.getToken();
        const headers = new HttpHeaders({
            'X-CSRF-Token': token,
            'Content-Type': 'application/json'
        });

        return this.http.patch(url, data, {headers: headers, withCredentials: true}).toPromise();
    }

    /**
     * Makes a request to drupal based on type and optional nid and returns an Observable
     * @param id
     * @returns {Observable<Object>}
     */
    get(id) {
        let url = window['DRUPAL_URL'] + 'node?_format=json&nid=' + id;
        return this.sendHttpRequest(url);
    }

    /**
     * Calls drupal view to search for term
     * @param term
     * @param searchBy
     * @param filterList
     * @param offset
     * @returns {Promise<Array<any>>}
     */
    search(term, searchBy, filterList, offset) {
        let url = window['DRUPAL_SEARCH_URL'] + '?_format=json&offset=' + offset;
        switch (searchBy) {
            case 'title':
                url += '&title=' + term;
                break;
            case 'body':
                url += '&body=' + term + '&existing_media=' + term;
                break;
            default:
                url += '&fulltext=' + term;
                break;
        }
        return this.sendHttpRequest(url);
    }

    /**
     * Returns formatted list of node ids related to given node id
     * @param nid
     */
    async getRelatedContent(nid) {
        let url = window['DRUPAL_URL'] + 'related-content?_format=json&nid=' + nid;
        await this.authService.getUser();
        return this.http.get<Array<any>>(url, {withCredentials: true}).toPromise().then(response => {
            if (!response[0] || response[0].field_related_content === '^~' || response[0].field_related_content === '') {
                return [];
            } // return empty array
            let related = response[0].field_related_content.split('^~');
            let relatedPages = [];
            let index = -1;
            if (!isNaN(related[0])) { // Special case for related pages with no groups
                relatedPages.push({title: '', pages: []});
                index = 0;
            }
            for (let r of related) {
                if (isNaN(r)) {
                    index++;
                    relatedPages.push({title: r, pages: []});
                } else {
                    relatedPages[index].pages.push(r);
                }
            }
            return relatedPages;
        });
    }

    getOems() {
      const url = window['DRUPAL_URL'] + 'oem_names';
      return this.http.get<Array<any>>(url, {withCredentials: true}).toPromise();
    }

    getGridPrograms(selectedFilters) {
      let url = window['DRUPAL_URL'] + 'bluedot/grid_resource?oems=';
      selectedFilters.forEach(filter => url += filter + ',');
      return this.http.get<Array<any>>(url, {withCredentials: true}).toPromise();
    }

    async getOemPrograms(franchises, locales) {
        let url = window['DRUPAL_URL'] + 'bluedot/blue_dot_resource?franchises=' + franchises + '&locales=' + locales;
        return this.http.get<Array<any>>(url, {withCredentials: true}).toPromise();
    }

    async getUserInfo(username?: string) {
        username = username ?? await this.authService.getUser();
        const url = `${window['DRUPAL_URL']}user_info?name=${username}&_format=json`;
        return this.http.get<Array<any>>(url, {withCredentials: true}).toPromise();
    }

    /**
     * Gets XSRF token
     * @returns {Promise<any>}
     */
    async getToken(): Promise<any> {
        const url = `${window['DRUPAL_URL_REST']}session/token`;
        await this.authService.getUser();
        return this.http.get(url, {responseType: 'text', withCredentials: true}).toPromise();
    }

    patchFeedFilter(filter, uid) {
        const url = window['DRUPAL_URL_REST'] + 'user/' + uid + '?_format=json';
        return this.sendPatchRequest(JSON.stringify({'field_wws_customize_feed': [filter]}), url);
    }

    /**
     * Patches user favorites in Drupal
     * @param favorites
     * @param uid
     * @returns {Promise<T>}
     */
    patchFavorites(favorites, uid) {
        let url = window['DRUPAL_URL_REST'] + 'user/' + uid + '?_format=json';
        return this.sendPatchRequest(JSON.stringify({'field_wws_favorites': [favorites]}), url);
    }

    /**
     * Patches the user profile in Drupal
     * @param profile
     * @param areas
     * @param uid
     */
    patchProfile(profile, areas, uid): Promise<any> {
        const url = window['DRUPAL_URL_REST'] + 'user/' + uid + '?_format=json';
        return this.sendPatchRequest(JSON.stringify({
            'field_wws_personalization': [{'value': JSON.stringify(profile)}],
            'field_wws_business_areas': [{'value': JSON.stringify(areas)}]
        }), url);
    }

    patchRecommended(recommended, uid) {
        let url = window['DRUPAL_URL_REST'] + 'user/' + uid + '?_format=json';
        let reco = JSON.stringify(recommended);
        return this.sendPatchRequest(JSON.stringify({'field_wws_recommended': [{'value': reco}]}), url);
    }

    /**
     * Constructs drupal query to retrieve list of taxonomy terms
     * @param type
     * @returns {Promise<Array<any>>}
     */
    getTaxonomyTerms(type, filter = null) {
        let url = window['DRUPAL_URL'] + 'taxonomy/getterms/' + type + '?_format=json';

        if (filter) {
          url += `&${filter}`;
        }

        return this.sendHttpRequest(url);
    }

    /**
     * Constructs drupal query to retrieve list of categories by user's selected organizations
     * @param filter
     */
    getCategoryByOrganization(filter) {
        let url = window['DRUPAL_URL'] + 'taxonomy/getterms/category_by_organization?_format=json&organization=' + filter;
        return this.sendHttpRequest(url);
    }

    /**
     * Constructs drupal query to retrieve list of terms
     * @param type
     * @param queries
     * @returns {Observable<Array<any>>}
     */
    getTerms(type, queries?) {
        let url = window['DRUPAL_URL'] + 'content/' + type + '?';
        if (queries) {
            for (let pair of queries) {
                url += pair.term + '=';
                for (let value of pair.values) {
                    url += value + ',';
                }
                url = url.substr(0, url.length - 1) + '&';
            }
            url = url.substr(0, url.length - 1) + '&_format=json';
        } else {
            url = url + '_format=json';
        }
        return this.sendHttpRequest(url);
    }

    /**
     * Do a drupal query with subqueries
     * @param type
     * @param queries
     * @returns {Promise<Array<any>>}
     */
    getWithFilters(type, queries): Promise<Array<any>> {
        let results = [];
        return this.authService.getUser().then(() => {
            return this.getWithFiltersHelper(type, queries, 0, results).then(response => {
                let items = [];
                let ids = {};
                for (let item of response) {
                    if (item.nid && !ids[item.nid]) {
                        ids[item.nid] = true;
                        items.push(item);
                    }
                }
                return items;
            });
        });
    }

    /**
     * Returns promise of content type filtered by queries
     * @param type
     * @param queries
     * @param index
     * @param results
     * @returns {Observable<Object>}
     */
    private getWithFiltersHelper(type, queries, index, results) {
        let url = window['DRUPAL_URL'] + type + '?';
        for (let pair of queries[index].query) {
            url += pair.term + '=';
            for (let value of pair.values) {
                url += value + ',';
            }
            url = url.substring(0, url.length - 1) + '&';
        }
        url = url.substring(0, url.length - 1) + '&_format=json';
        return this.http.get<Array<any>>(url, {withCredentials: true}).toPromise().then(response => {
            index++;
            const newResults = results.concat(response);
            return index === queries.length ? newResults : this.getWithFiltersHelper(type, queries, index, newResults);
        });
    }

}
