export class Profile {
  personalization: {
    organization: [];
    locale: [];
    language: [];
  };
  'business Area': [];

  constructor() {}
}
