import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {DrupalService} from '../../service/drupal/drupal.service';
import {StringService} from '../../service/string/string.service';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

    @Input() page;
    @Input() title?: string;
    @Input() category?: string;
    breadcrumbs = [];
    routeSub;

    constructor(private router: Router) {
    }

    /**
     * Get breadcrumb trail
     */
    ngOnInit() {
        this.routeSub = this.router.events.subscribe(event => {
            if (event instanceof ActivationEnd) {
                this.getContent();
            }
        });
        this.getContent();
    }

    getURLString(string) {
        return StringService.getURLString(string);
    }

    /**
     * Gets current page and parent page information for breadcrumbs
     * Breadcrumbs for this site will only ever be two layers deep (?)
     */
    async getContent() {
      if (!this.title) {
        const currentRoute = this.router.url.toLowerCase().split('/');
        this.title = StringService.getStringFromURL(currentRoute[currentRoute.length - 1]);
      }

      if (this.category) {
        this.breadcrumbs.push(this.category);
      }

      // setTimeout(() => {
      //   if (!this.page) {
      //     let currentRoute = this.router.url.toLowerCase().split('/');
      //     if (currentRoute[currentRoute.length - 2].toLowerCase() === 'search') {
      //       this.title = 'Search';
      //     } else {
      //       this.title = StringService.getStringFromURL(currentRoute[currentRoute.length - 1]);
      //     }
      //   }
      // }, 200);
    }

}
