import {Component, OnDestroy, OnInit} from '@angular/core';
import {StringService} from '../../service/string/string.service';
import {FilterService} from '../../service/filter/filter.service';
import {Filter} from '../../service/filter/filter.interface';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {GoogleAnalyticsService} from '../../service/google-analytics/google-analytics.service';
import {RouteResolverService} from "../../service/route-resolver/route-resolver.service";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss', '../list/list.component.scss'],
  host: {'(document:scroll)': 'handleScroll()'}
})
export class SearchComponent implements OnInit, OnDestroy {

  public query = '';
  public filtersList: Filter = {filters: []};
  public searchBy = 'everything';
  public loading: boolean;
  public searchResults = [];
  public filter = '';

  private routeSub;

  constructor(
    private filterService: FilterService,
    private router: Router,
    private route: ActivatedRoute,
    private googleAnalyticsService: GoogleAnalyticsService,
    private routeResolverService: RouteResolverService
  ) {
    this.initSessionStorage();
  }

  ngOnInit() {
    this.routeSub = this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.search(event.snapshot.paramMap.get('query'));
      }
    });
    this.getFilters();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.query = '';
    this.routeSub = null;
  }

  /**
   * Get filters and deselect all of "content type"
   */
  async getFilters() {
    this.filtersList = await this.filterService.getFilters(['organization', 'business Area', 'content type', 'locale']);
    for (let f of this.filtersList.filters) {
      f.hidden = false;
      if (f.title === 'content type') {
        for (let item of f.items) {
          item.checked = false;
          f.visibleList[item.name] = false;
        }
      }

    }
    this.search(this.route.snapshot.paramMap.get('query'));
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.navigateForSearch();
    }
  }

  /**
   * Navigate to new url for search to maintain history
   */
  navigateForSearch() {
    if (this.route.snapshot.paramMap.get('query') !== this.query) {
      this.searchResults = [];
      this.router.navigateByUrl('/search/' + this.query);
    }
  }

  // Scroll handler
  handleScroll() {
    if (window.scrollY + window.innerHeight === document.documentElement.scrollHeight && this.searchResults.length > 0) {
      this.getResults();
    }
  }

  /**
   * Do a new search query
   * @param queryParam
   */
  search(queryParam?) {
    this.searchResults = [];
    if (queryParam) {
      this.query = queryParam;
      this.updateKeywordList();
    }
    if (this.query !== '') {
      this.getResults();
    }
  }

  /**
   * Get search results by query
   */
  getResults() {
    let hitBottom = Math.round(this.searchResults.length / window['LIST_PAGE_SIZE']);
    if (!this.loading && this.searchResults.length === hitBottom * window['LIST_PAGE_SIZE']) {
      this.loading = true;
      let origin = this.routeResolverService.prevRoutePath.value;
      this.googleAnalyticsService.sendAction('search', {
        event_name: 'search',
        search_term: this.query,
        origin: origin.includes('search') ? '/search' : origin
      });

      const filter: Filter = JSON.parse(JSON.stringify(this.filtersList));
      filter.filters.push({title: 'offset', items: [{name: hitBottom * window['LIST_PAGE_SIZE'], checked: true}]});

      // Build query
      if (this.searchBy === 'title') {
        filter.filters.push({title: 'title', items: [{name: encodeURIComponent(this.query), checked: true}]});
      } else if (this.searchBy === 'body') {
        filter.filters.push({title: 'body', items: [{name: encodeURIComponent(this.query), checked: true}]});
      } else {
        filter.filters.push({title: 'fulltext', items: [{name: encodeURIComponent(this.query), checked: true}]});
      }

      // Send
      this.filterService.getWithFilter('search', filter).then((response: any) => {
        this.searchResults = this.searchResults.concat(response);
        this.loading = false;
      });
    }

  }

  getURLString(string) {
    return StringService.getURLString(string);
  }

  gaContentEvent(item) {
    return {
      nid: item.nid,
      source: this.router.url,
      root_screen: item.field_category,
      title: item.title,
      media_title: item.field_media?.split('^')[0],
      content_type: item.field_type,
      advanced_content_type: item.field_advanced_search,
      organizations: item.field_companies_tids,
      locations: item.field_locales ? item.field_locales : item.field_locale,
      searching: true
    };
  }

  gaSearchResultEvent(item) {
    const searchTerms = JSON.parse(sessionStorage.getItem('search_keywords'));
    const organizationFilters = this.filtersList.filters
      .find(filter => filter.title === 'organization')
      .items
      .filter(locale => locale.checked)
      .map(locale => locale.tid)
      .join(',');
    const contentTypeFilters = this.filtersList.filters
      .find(filter => filter.title === 'content type')
      .items
      .filter(locale => locale.checked)
      .map(locale => locale.tid)
      .join(',');
    const localeFilter = this.filtersList.filters
      .find(filter => filter.title === 'locale')
      .items
      .filter(locale => locale.checked)
      .map(locale => locale.tid)
      .join(',');

    return {
      nid: item.nid,
      title: item.title,
      content_type: item.field_type,
      advanced_content_type: item.field_advanced_search,
      content_organizations: item.field_companies_tids,
      last_search_term: this.query,
      search_refinements: searchTerms.length,
      keyword_list: searchTerms.toString(),
      organization_filters: organizationFilters,
      content_type_filters: contentTypeFilters,
      locale_filters: localeFilter,
      search_by: this.searchBy
    }
  }

  /**
   * Initialize the search_keywords arrays
   *
   * This variable is intended to track the keywords entered until the user leaves the search page or the session ends
   */
  initSessionStorage() {
    sessionStorage.setItem('search_keywords', JSON.stringify([]));
  }

  /**
   * Update the search_keywords session variable with the new search query
   */
  updateKeywordList() {
    let keywords = JSON.parse(sessionStorage.getItem('search_keywords'));
    if (!keywords.includes(this.query)) {
      keywords.push(this.query);
      sessionStorage.setItem('search_keywords', JSON.stringify(keywords));
    }
  }

  routeToContent(item) {
    this.googleAnalyticsService.sendAction('Content', this.gaContentEvent(item));
    this.googleAnalyticsService.sendAction('SearchResult', this.gaSearchResultEvent(item));
  }

  getValue(event): string {
    let val = (event.target as HTMLSelectElement).value
    return val.charAt(0).toUpperCase() + val.slice(1);
  }
}
