<ng-template #recommendModal id="recommend-modal" tabindex="-1" role="dialog"
             aria-labelledby="recommend-modal" aria-hidden="true">
  <div class="fa-times-thin modal-close" (click)="recommendModalRef.dismiss()"
       [gaEvent]="{category: 'Recommend Modal', action: 'click', label: 'Close'}"></div>
  <div class="modal-header source-sans">
    <h3>Recommend Page <span class="loader-lite" *ngIf="loading"></span></h3>
  </div>
  <div class="modal-body source-sans">
    <div class="recommend-modal-col customize-grid">
      <div class="customize-column">
        <h4>Direct Reports</h4>
        <ul>
          <li *ngFor="let user of reports" (click)="user['checked'] = !user['checked']">
            {{user.name}}
            <span *ngIf="user.status && user.status === SUCCESS" class="success">- Success</span>
            <span *ngIf="user.status && user.status === ALREADY_HAS" class="success">- Already recommended</span>
            <span *ngIf="user.status && user.status === NO_USER" class="warning">- No user found</span>
            <span *ngIf="user.status && user.status === FAILED" class="error">- Failed</span>
            <div class="star" [ngClass]="{'checked' : user.checked}"
                 [gaEvent]="{category: 'Recommend Modal', action: 'click', label: user.name}">
              <span class="far fa-square" *ngIf="!user.checked"></span>
              <span class="fas fa-check-square check" *ngIf="user.checked"></span>
            </div>
          </li>
        </ul>
        <ul class="customize-grid-options">
          <li class="deselect" (click)="deselectAll()">Deselect All <i class="star fas fa-minus-square"></i></li>
          <li class="select" (click)="selectAll()">Select All <i class="star fas fa-check-double"></i></li>
        </ul>
      </div>
    </div>
    <div style="text-align: right">
      <div class="btn btn-special" (click)="recommendModalRef.dismiss()"
           [gaEvent]="{category: 'Recommend Modal', action: 'click', label: 'Cancel'}">Done
      </div>
      <div class="btn btn-grey" (click)="send()">Send</div>
    </div>
  </div>
</ng-template>
