import {Component, Input, OnInit} from '@angular/core';
import {StringService} from '../../service/string/string.service';
import { GoogleAnalyticsService } from "../../service/google-analytics/google-analytics.service";
import {FeedComponent} from '../feed.component';
import {Router} from "@angular/router";

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
})
export class ResourcesComponent extends FeedComponent {

    @Input() loading;
    @Input() parentId;

    constructor(
        private googleAnalyticsService: GoogleAnalyticsService,
        private router: Router
    ) {
        super();
        this.title = "Related Resources";
        this.init(0, 99);
    }

    selectFilter(string){
        this.filter = string;
    }

    getURLString(item){
        return StringService.getURLString(item);
    }

    stripContainingHTML(string){
        return StringService.stripContainingHTML(string);
    }

    gaContentEvent(item) {
        return {
            event_name: 'Content',
            nid: item.nid,
            source: this.router.url,
            root_screen: item.field_category,
            title: item.title,
            media_title: item.field_media?.split('^')[0],
            content_type: item.field_type,
            advanced_content_type: item.field_advanced_search,
            organizations: item.field_organization_tids,
            locations: item.field_locales ? item.field_locales : item.field_locale,
            searching: false
        };
    }

    sendGaAccordionExpansionEvent(item) {
        this.googleAnalyticsService.sendAction('AccordionExpansion',{
            event_name: 'AccordionExpansion',
            title: item.title,
            source: this.router.url
        });
    }
}
