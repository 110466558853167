<input id="filter-open" type="checkbox" name="filter-open" value="none" (change)="sendGaFilterModalEvent($event)"
       [(ngModel)]="filterOpen">
<label for="filter-open" class="filter-button">
  Filters <i class="fas fa-chevron-down"></i> <span style="margin-left: 20px;"
                                                    *ngIf="selectedCount && filterCount && selectedCount !== filterCount">({{ selectedCount }}
  filter<span *ngIf="selectedCount > 1">s</span> applied)</span>
</label>
<div class="filters-holder">
  <ng-container *ngFor="let category of filtersList.filters">
    <div class="filter-category" [ngClass]="{'hidden': category['hidden']}">
      <h6 [innerHTML]="getTitleFromField(category.title)"></h6>
      <!--<app-dropdown-multiselect [options]="category.items" [title]="getTitleFromField(category.title)" [id]="category.title" (emitInputChange)="filterChange($event)"></app-dropdown-multiselect>-->
      <ul>
        <ng-container *ngFor="let item of category.items; let i = index">
          <li *ngIf="item.hidden == false" (click)="toggleFilter(category, item.name, i)">
            <span [innerHTML]="item.name"></span>
            <!--<div class="star filter" [ngClass]="{'checked': category.visibleList[item.name]}" [gaEvent]="{category: category.title + ' List Filter', action: 'click', label: item.name}">-->
            <div class="star filter" [ngClass]="{'checked': category.visibleList[item.name]}">
              <span class="far fa-square" *ngIf="!category.visibleList[item.name]"></span>
              <span class="fas fa-check-square check" *ngIf="category.visibleList[item.name]"></span>
            </div>
          </li>
        </ng-container>
      </ul>
      <div class="filter-column-toggle">
        <div class="filter-toggle-option" (click)="deselectCategory(category)">
          <i class="fas fa-minus-circle"></i>
          <span>Deselect all</span>
        </div>
        <div class="filter-toggle-option" (click)="selectCategory(category)">
          <span>Select all</span>
          <i class="fas fa-check-double blue"></i>
        </div>
      </div>
    </div>
  </ng-container>
</div>
