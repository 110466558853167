import {Component, ViewChild} from '@angular/core';
import {CustomizeModalComponent} from '../customize-modal/customize-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent  {

  @ViewChild(CustomizeModalComponent) customizeModal;

  constructor() { }

  // Called from child to refresh custom filters
  refreshFilters(){
    this.customizeModal.open();
  }
}
