import {Injectable} from '@angular/core';
import {DrupalService} from '../drupal/drupal.service';
import {Filter} from './filter.interface';
import {StringService} from '../string/string.service';
import {RecentUpdatesComponent} from '../../feed/recent-updates/recent-updates.component';
import {PersonalizationService} from '../personalization/personalization.service';
import {Subject} from 'rxjs';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private recentUpdatesFilterSubject = new Subject<any>();
  public recentUpdatesStream = this.recentUpdatesFilterSubject.asObservable();

  private userOrganizationFilterSubject = new Subject<any>();
  public userOrganizationStream = this.userOrganizationFilterSubject.asObservable();

  private userOrganizationFilter;
  private recentUpdatesFilter;
  private allFilters;
  private oldFilters;

  constructor(
    private drupalService: DrupalService,
    private personalizationService: PersonalizationService,
    private authService: AuthService
  ) {
    this.authService.completeUser$.subscribe((complete) => {
      if (complete) {
        this.allFilters = this.getFilterCriteria();
        this.personalizationService.newProf$.subscribe(
          prof => {
            this.allFilters = this.rebuildFilters();
            this.setOrganizationsFilter();
          }
        );
      }
    });
  }

  async getFilterCriteria() {
    let filter: Filter = {filters: []};
    return Promise.all([
      this.buildFilter(filter, this.drupalService.getTaxonomyTerms('category'), 'category'),
      this.buildFilter(filter, this.drupalService.getTaxonomyTerms('advanced-search-type'), 'content type'),
      this.buildProfileFilter(filter, this.personalizationService.getProfileFilters()),
      this.buildFilter(filter, this.drupalService.getTaxonomyTerms('file-type'), 'type'),
      // this.buildFilter(filter, this.drupalService.getTaxonomyTerms('dealership-area'), 'business Area'),
    ]).then(() => {
      this.oldFilters = filter.filters;
      return filter;
    });
  }

  async getBlueDotFilters() {
    let filter: Filter = {filters: []};
    return Promise.all([
      this.buildBlueDotFilter(filter, this.drupalService.getTaxonomyTerms('blue-dot-business-area'), 'Business Area'),
      this.buildBlueDotFilter(filter, this.drupalService.getTaxonomyTerms('blue-dot-department'), 'Department'),
      this.buildBlueDotFilter(filter, this.drupalService.getTaxonomyTerms('blue-dot-program-type'), 'Program Type'),
      this.buildBlueDotFilter(filter, this.drupalService.getTaxonomyTerms('blue-dot-program-status'), 'Program Status')
    ]).then(() => {
      return filter;
    });
  }

  async rebuildFilters() {
    let filter: Filter = {filters: []};
    await this.buildProfileFilter(filter, this.personalizationService.getProfileFilters());
    filter.filters.push(JSON.parse(JSON.stringify(this.oldFilters.find((filt => filt.title == 'type')))));
    filter.filters.push(JSON.parse(JSON.stringify(this.oldFilters.find((filt => filt.title == 'category')))));
    filter.filters.push(JSON.parse(JSON.stringify(this.oldFilters.find((filt => filt.title == 'content type')))));
    return filter;
  }

  async setOrganizationsFilter() {
    let newFilters = await this.getOrganizationsFilter();
    this.userOrganizationFilterSubject.next(newFilters);
  }

  /**
   * Return users selected organizations
   */
  async getOrganizationsFilter() {
    let filter = await this.getFilters(['organization']);
    this.userOrganizationFilter = filter;
    return filter;
  }

  /**
   * Returns the saved Recent Updates filter from localstorage or returns the default
   * @returns {any}
   */
  async getRecentUpdatesFilter() {
    if (!this.recentUpdatesFilter) {
      const userInfo = await this.drupalService.getUserInfo();
      const favorites = userInfo[0]['field_wws_customize_feed'];
      let filter = await this.getFilters(['category', 'content type', 'type']);
      filter = this.setFiltersFalse(filter);
      if (favorites !== '') {
        for (let favorite of favorites.split(',')) {
          for (let f of filter.filters) {
            for (let item of f.items) {
              if (favorite === item.tid.toString()) {
                item.checked = true;
              }
            }
          }
        }
      }
      this.recentUpdatesFilter = filter;
    }
    return JSON.parse(JSON.stringify(this.recentUpdatesFilter));
  }

  // Set all filters false
  setFiltersFalse(filter) {
    for (let f of filter.filters) {
      for (let item of f.items) {
        item.checked = false;
      }
    }
    return filter;
  }

  async buildProfileFilter(filter, filtersPromise) {
    let filters = (await filtersPromise).filters;
    for (let items of filters) {
      let filterHolder = {};
      let newItems = [];
      for (let category of items.items) {
        category['hidden'] = false;
        filterHolder[category.name] = !!(category['checked']);
        category['checked'] = !!(category['checked']);
        if (category['field_organizations']) {
          category['organizations'] = category['field_organizations'].split(', ');
        }
        newItems.push(category);
      }
      filter.filters.push({
        title: items.title,
        items: newItems,
        visibleList: filterHolder
      });
    }
    return;
  }

  // Helper function to build default filters
  private async buildFilter(filter, itemsPromise, categoryName) {
    let items = await itemsPromise;
    let filterHolder = {};
    for (let category of items) {
      category['hidden'] = false;
      category['checked'] = true;
      if (category['field_organizations']) {
        category['organizations'] = category['field_organizations'].split(', ');
      }
      filterHolder[category.name] = true;
    }
    filter.filters.push({
      title: categoryName,
      items: items,
      visibleList: filterHolder
    });
    return;
  }

  private async buildBlueDotFilter(filter, itemsPromise, categoryName) {
    const items = await itemsPromise;
    const categoryEnum = {
      'Business Area': 0,
      'Department': 1,
      'Program Type': 2,
      'Program Status': 3
    }
    let filterHolder = {};

    for (let category of items) {
      category.name = category.name.replace(/&amp;/g, '&');
      category.hidden = false;
      category.checked = true;
      filterHolder[category.name] = true;
      if (category['field_organizations']) {
        category['organizations'] = category['field_organizations'].split(', ');
      }
    }
    if (categoryName === 'Program Status') {
      filterHolder['Other'] = true;
      items.push({
        name: 'Other',
        hidden: false,
        checked: true
      });
    }

    filter.filters[categoryEnum[categoryName]] = {
      title: categoryName,
      items: items,
      visibleList: filterHolder
    };
  }

  /**
   * Save the filter to localstorage and drupal
   * @param filter
   */
  async setRecentUpdatesFilter(filter: { filters: any; }) {
    const formattedFilter = [];
    for (let f of filter.filters) {
      for (let item of f.items) {
        if (item.checked) {
          formattedFilter.push(item['tid']);
        }
      }
    }
    const filterString = formattedFilter.toString();
    const userInfo = await this.drupalService.getUserInfo();
    await this.drupalService.patchFeedFilter(filterString, userInfo[0].uid);
    this.recentUpdatesFilter = filter;
    const newFilters = await this.getRecentUpdatesFilter();
    this.recentUpdatesFilterSubject.next(newFilters);
  }

  findFilterByName(name, allFilters) {
    for (let filter of allFilters.filters) {
      if (filter.title.toLowerCase() === name.toLowerCase()) {
        return filter;
      }
    }
    return {};
  }

  /**
   * Get filters list from Drupal
   */
  async getFilters(types) {
    let filter = await this.allFilters;
    let newFilters = {filters: []};
    for (let type of types) {
      newFilters.filters.push(this.findFilterByName(type, filter));
    }
    // Deep clone the object so we don't screw up other filters
    return JSON.parse(JSON.stringify(newFilters));
  }

  getQuery(filterList: Filter) {
    let query = [];
    for (let filter of filterList.filters) {
      let term = StringService.makeFieldName(filter.title);
      let values = [];
      for (let item of filter.items) {
        if (item.checked) {
          values.push(item.name);
        }
      }
      query.push({term: term, values: values});
    }
    return query;
  }

  /**
   * Get nodes from Drupal filtered by the passed Filter object
   * @param type
   * @param filterList
   * @param org?
   * @returns {Promise<Array<any>>}
   */
  getWithFilter(type, filterList: Filter, org?) {
    let query = this.getQuery(filterList);

    if (org === 'title') {
      return this.drupalService.getWithFilters(type + '_by_title', [{query: query}]);
    } else {
      return this.drupalService.getWithFilters(type, [{query: query}]);
    }
  }

  /**
   * Gets recent updates by the current recent updates filter
   * @param filters
   * @param type
   * @param offset
   * @returns {Promise<any[]>}
   */
  async getRecentUpdatesWithFilter(filters, type, offset) {
    const query = this.getQuery(filters);
    query.push({term: 'main_page', values: ['0']});
    query.push({term: 'offset', values: [offset]});
    query.push({term: 'exclude_recent_updates', values: ['0']});
    return this.drupalService.getWithFilters(type, [{query: query}]);
  }

  filterOut(item, filter: Filter) {
    for (let category of filter.filters) {
      let fieldName = 'field_' + StringService.makeFieldName(category.title);
      if (!item[fieldName]) {
        continue;
      }
      if (!this.isInList(item[fieldName], category.visibleList)) {
        return false;
      }
    }
    return true;
  }

  isInList(item, list) {
    let itemVals = item.split(', ');
    for (let val of itemVals) {
      if (list[val]) {
        return true;
      }
    }
    return false;
  }

}
