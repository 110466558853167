import {AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {GoogleAnalyticsService} from "../service/google-analytics/google-analytics.service";
import {DatePipe} from "@angular/common";

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('browserWarning') browserWarning: ElementRef;
  @HostListener('window:beforeunload')
  beforeUnloadHandler() {
    this.googleAnalyticsService.sendAction('PageExit', {
      time_on_page: Math.round((new Date().getTime() - this.startTime) / 1000),
      percent_scrolled: `Scroll ${this.scrollPercentage}%`
    });
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {this.updateScrollProgress()}

  title = 'What We Sell';
  scrollPercentage = 0;
  startTime: number;
  scrollDebounce = null;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private datePipe: DatePipe,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd) || event.urlAfterRedirects.indexOf('css-auth-validation') > -1) {
        return;
      }
      this.startTime = new Date().getTime();
      gtag('js', new Date());
      gtag('config', 'G-E5FWDTH7GP', {send_page_view: false});
    });
  }

  ngOnInit() {
    this.router.events.subscribe(event => (event instanceof NavigationEnd) && window.scrollTo(0, 0));
    // Get date for print formatting
    const html = document.getElementsByTagName('html')[0];
    html.style.setProperty("--current-date-full", `'${this.datePipe.transform(new Date(), 'MMM dd YYYY')}'`);
    html.style.setProperty("--current-year", `'${this.datePipe.transform(new Date(), 'YYYY')}'`);
  }

  ngAfterViewInit() {
    this.getBrowser();
  }

  hideWarning() {
    this.renderer.setStyle(this.browserWarning.nativeElement, 'display', 'none');
  }

  /**
   * Check browser on startup
   */
  getBrowser() {
    if (this.detectBadBrowser()) {
      this.renderer.setStyle(this.browserWarning.nativeElement, 'display', 'block');
    }
  }

  /**
   * Detects browser version, returning false if a supported browser
   */
  detectBadBrowser() {
    const badBrowsers = ['MSIE ', 'Trident/', 'Firefox/', 'Edge'];
    const index = badBrowsers.findIndex(browser => window.navigator.userAgent.indexOf(browser) > 0);
    return index > 0;
  }

  updateScrollProgress() {
    if (this.scrollDebounce) {
      clearTimeout(this.scrollDebounce);
    }

    this.scrollDebounce = setTimeout(() => {
      const footerHeight = document.querySelector('footer').clientHeight;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight - footerHeight;
      const scrollPosition = document.documentElement.scrollTop / height;
      this.scrollPercentage = 25 * Math.floor(4 * scrollPosition); //floor to nearest 25
      this.scrollDebounce = null;
    }, 100);
  }
}
