<div class="main">
  <div class="list-grid">
    <div class="col-left">
      <app-breadcrumbs></app-breadcrumbs>
      <div class="article-text">
        <h1 [innerHTML]="title"></h1>
        <div class="body-copy">
          <div style="position: relative;">
            <input #filterInput class="filter-input" placeholder="Filter {{title}}" (keyup)="searchChange()">
          </div>
          <div class="search-show">
            <div class="search-filter" *ngIf="title.toLowerCase() === 'news'">
              <span style="margin-right: 2px;">Order by:</span>
              <select id="search-filter-dropdown" (change)="selectOrg($event)" >
                <option value="date">Date</option>
                <option value="title">Title</option>
              </select>
            </div>
            <app-filter (update)="updateList()" [(filtersList)]="filtersList"></app-filter>
          </div>

          <!-- List items -->
          <div *ngIf="title.toLowerCase() !== 'news'">
            <div *ngIf="loading">
              <div class="list-item" *ngFor="let item of numStandins">
                <div class="standin-date"></div>
                <div class="standin-header"></div>
              </div>
            </div>
            <div *ngIf="feedType === 'oem' && oemGridEnabled" class="list-item">
              <div style="display: flex">
                <div style="display: inline-block">
                  <i class="fas fa-thumbtack pinned-icon"></i>
                  <a class="header-5" routerLink="/oem-certifications-grid">OEM Certifications Grid</a>
                </div>
              </div>
            </div>
            <div class="list-item" *ngFor="let item of filteredList" [ngClass]="{'hidden': item['hidden']}">
              <div style="display: flex">
                <div style="display: inline-block">
                  <i *ngIf="item.field_sticky === 'True'" class="fas fa-thumbtack pinned-icon"></i>
                  <a class="header-5" [innerHTML]="stripContainingHTML(item.title)" routerLink="/{{getURLString(item.field_category)}}/{{item.nid}}"
                     [gaEvent]="gaContentEvent(item)"
                  ></a>
                  <p class="list-copy" [innerHTML]="stripContainingHTML(item.field_summary)"></p>
                </div>
              </div>
            </div>
          </div>

          <!-- News items -->
          <div *ngIf="title.toLowerCase() === 'news'">
            <div *ngIf="loading">
              <div class="news-item" *ngFor="let item of numStandins">
                <div class="standin-date"></div>
                <div class="standin-header"></div>
                <div class="standin-body-copy"></div>
              </div>
            </div>
            <app-news-item *ngFor="let item of filteredList" [ngClass]="{'hidden': item['hidden']}" [item]="item"></app-news-item>
          </div>
        </div>
      </div>
    </div>
    <div class="col-right">
      <app-related></app-related>
    </div>
  </div>
</div>
